import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import { formatFullPrice, formatTime } from '@/utils';
import { globalState } from '@/stores';
import './VerticalItems.scss'
import NP from '@/utils/NP';
import { APPLY_TYPE, DISCOUNT_TYPE, ORDER_STATUS_TYPE } from '../enum'
interface IProps {
  itemsTableData: any
  posOrderCheckDetailView: any
  detailInfo: any
}

enum DISCOUNT_ITEM_TYPE {
  ITEM,
  CHECK
}

const RenderNotes = (notes: any[]) => {
  if (notes.length > 0) {
    const remark = notes[0]?.choiceRemarks?.map(item => item.name).join('、');
    const customRemark = notes[0].customRemark;
    if (!remark && !customRemark) {
      return '-'
    }
    const remarkList = [remark, customRemark].filter(x => x)
    return <Tooltip placement="topLeft" title={remarkList.join('、')}>{remarkList.join('、')}</Tooltip>
  }
  return '-'
}
const VerticalItems = (props: IProps) => {
  const i18n = globalState.i18n;
  const { posOrderCheckDetailView, detailInfo } = props;

  const RenderModifiers = (modifiers: any[]) => {
    const text = modifiers.length > 0 && modifiers?.map(item => `${item.modifierName} ${formatFullPrice(item.preDiscountSubTotal)} x ${item.unitQuantity} `).join(', ');
    return text ? <Tooltip placement="topLeft" title={text}>{text}</Tooltip> : '-'
  }
  const RenderDiscount = (discounts: any[], type: DISCOUNT_ITEM_TYPE) => {
    const isOrderCanceled = detailInfo?.posOrderBaseTO?.orderStatusCode === ORDER_STATUS_TYPE.CANCELED;
    if (isOrderCanceled && type === DISCOUNT_ITEM_TYPE.ITEM) return ''
    const percents = [DISCOUNT_TYPE.PERCENT, DISCOUNT_TYPE.OPEN_PERCENT, DISCOUNT_TYPE.COMP_OPEN_PERCENT];
    if (discounts && discounts.length > 0) {
      return discounts.map(m => <div className='vertical-items-info-discount' key={m.applyTargetId}>
        <div className='vertical-items-info-discount-label'>{ getDiscountDisplayName(m) } {percents.includes(m.discountTypeCode) ? `(${NP.times(m.discountPercent, 100).toFixed(2)}%)` : ''}</div>
        <div className="vertical-items-info-discount-value">{ !!m.applyAmount ? '-' : '' }{ isOrderCanceled ? '$0.00' : formatFullPrice(m.applyAmount) }</div>
      </div>)
    }
  }

  const getDiscountDisplayName = (item) => {
    const { applyTypeCode, discountTypeCode, name } = item;
    const applyType = {
      [APPLY_TYPE.ITEM]: i18n.t('pc_orderDetail_item'),
      [APPLY_TYPE.CHECK]: i18n.t('pc_orderDetail_check')
    }
    const discountType = {
      [DISCOUNT_TYPE.OPEN_PERCENT]: `${i18n.t('pc_orderDetail_discount')} %`,
      [DISCOUNT_TYPE.OPEN_FIXED]: `${i18n.t('pc_orderDetail_discount')} $`,
      [DISCOUNT_TYPE.COMP_OPEN_PERCENT]: `${i18n.t('pc_orderDetail_comp')} %`,
      [DISCOUNT_TYPE.COMP_OPEN_FIXED]: `${i18n.t('pc_orderDetail_comp')} $`,
    }
    return `${ [DISCOUNT_TYPE.PERCENT, DISCOUNT_TYPE.FIXE].includes(discountTypeCode) ? name : applyType[applyTypeCode] + ' ' + discountType[discountTypeCode] }`
  }

  const Line = (props) => (<svg width="100%" height="1" {...props} style={{ marginBottom: '5px' }}>
    <line x1="0" y1="0" x2="700" y2="0" stroke="#BEBEBE" strokeWidth={ 2 } strokeDasharray="5, 5"/>
  </svg>)

  return (
    <div className="vertical-items">
      <div>
        {
          props.itemsTableData.map(m => (
            <div className="vertical-items-info" key={ m.id }>
              <div className="vertical-items-info-desc">
                <div className={ `vertical-items-info-desc-title ${ m.status.value === '4' ? 'vertical-items-info-desc-title-line-through' : '' }` }>{ m.itemName }</div>
                <div className="vertical-items-info-desc-qty">{ m.status.value !== '4' ? `x${ m.actualQuantity ? parseFloat(m.actualQuantity).toFixed(2) : '' }` : '' }</div>
                <div className={ `vertical-items-info-desc-total ${ m.status.value === '4' ? 'vertical-items-info-desc-total-voided' : '' }` }>{ m.status.value !== '4' ? formatFullPrice(m?.preDiscountSubTotal) : <div className='total-voided-label'>{ i18n?.t('orderDetail_pc_voided') }</div> }</div>
              </div>
              { !!m.modifiers.length && <div className="vertical-items-info-modifiers">
                { RenderModifiers(m.modifiers) }
              </div> }
              { detailInfo?.posOrderBaseTO?.orderStatusCode !== ORDER_STATUS_TYPE.CANCELED && m.appliedPromotionActivities && <div className="vertical-items-info-discounts">
                { RenderDiscount(m.appliedPromotionActivities, DISCOUNT_ITEM_TYPE.ITEM) }
              </div> }
              { !!m.orderRemarkTO.length && <div className="vertical-items-info-notes">
                { i18n?.t('orderDetail_remarks') }: { RenderNotes(m.orderRemarkTO) }
              </div> }
              { !!m.sendTime && <div className="vertical-items-info-send-time">Sent { formatTime(m.sendTime, 'hh:mm tt') }</div> }
            </div>
          ))
        }
      </div>
      <Line/>
      {
        posOrderCheckDetailView.appliedPromotionActivities && <div className="vertical-items-check-discount-wrap">
          { RenderDiscount(posOrderCheckDetailView.appliedPromotionActivities, DISCOUNT_ITEM_TYPE.CHECK) }
          <Line style={ { height: '6px' } }/>
        </div>
      }
    </div>
  );
}
export default VerticalItems;
export {
  RenderNotes
};