import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import './index.scss'
import PepprDatePicker, { DATE_RANGE_TYPE } from '@/Components/DatePicker';
import { Button, Select, Table, message, Empty, Spin } from 'antd';
import useGetState from '@/hooks/useGetState';
import dayjs, { Dayjs } from 'dayjs';
import { getToday, removeNullUndefinedFields, errorOutput, downloadFile } from '@/utils';
import { ApiQueryEmployeeList, ApiQueryLaborReport, ApiExportLaborSummary, ApiApaasQueryExportResult } from '@/request/api';
import { ColumnsType } from 'antd/es/table';
import { SyncOutlined } from '@ant-design/icons';
import { RangeValueType } from '@/types';

interface IPage {
  current: number;
  pageSize: number;
  pageIndex: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

interface IParamsState {
  date: RangeValueType,
  employeeIdList: Array<number>,
  sortByDescTime: boolean,
  dateRangeType: string
}

interface ILaborSummary {
  totalLaborHours: string,
  totalHourlyRate: string,
  totalEstimatedTotalPay: string,
  totalCashTips: string,
  totalCardTips: string,
  totalTips: string,
}

export default function LaborSummary () {
  const { i18n } = globalState;

  const [isInit, setIsInit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [rangePresets, setRangePresets] = useState([])
  const [employeeAllList, setEmployeeAllList] = useState([])
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)
  const [tableData, setTableData] = useState([])
  const [showColumnsList, setShowColumnsList] = useState([])
  const [params, setParams, getParams] = useGetState<IParamsState>({
    date: [dayjs(), dayjs()],
    employeeIdList: [],
    sortByDescTime: false,
    dateRangeType: DATE_RANGE_TYPE.TODAY
  })
  const [isExporting, setIsExporting] = useState(false)
  const [intervalId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  let exportTaskId;

  const tableColumns = [
    {
      dataIndex: 'roleId', key: 'roleId', title: i18n?.t('dashboard_report_labor_summary_role'), width: 200,
      render: (_, record) => record.roleName || '-',
      align: 'center'
    },
    {
      dataIndex: 'laborHours', key: 'laborHours', title: i18n?.t('laborSummary_totalLaborHours'), width: 200,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
      align: 'center'
    },
    {
      dataIndex: 'hourlyRate', key: 'hourlyRate', title: i18n?.t('laborSummary_hourlyRate'), width: 200,
      render: (val) => !!val ? `$${parseFloat(val).toFixed(2)}` : 'N/A',
      align: 'center'
    },
    {
      dataIndex: 'estimatedTotalPay', key: 'estimatedTotalPay', title: i18n?.t('laborSummary_estimatedTotalPay'), width: 200,
      render: (val) => !!val ? `$${parseFloat(val).toFixed(2)}` : 'N/A',
      align: 'center'
    }
  ]

  useEffect(() => {
    initPage();
  }, []);

  const initPage = async () => {
    setShowColumnsList(tableColumns.map(x => x.dataIndex))
    fetchEmployeeList();
    const _today = await getToday();
    setToday(_today);
    setParams({ ...params, date: [_today, _today] });
    await fetchTableData();
    setIsInit(true);
  }

  const fetchEmployeeList = async () => {
    const _params = { pageIndex: 1, pageSize: 99999, total: 0 }
    const res = await ApiQueryEmployeeList(_params)
    if (res.code !== 0) return;
    const { dataList = [] } = res.data;
    setEmployeeAllList(dataList.map(x => ({ label: x.employee.fullName, value: x.employee.employeeId })))
  }

  const fetchTableData = async () => {
    const { date, ...curParams } = getParams();
    const reqParams = {
      startTime: date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: date[1].format('YYYY-MM-DD 23:59:59'),
      employeeId: !curParams.employeeIdList || curParams.employeeIdList?.length === 0 ? '' : curParams.employeeIdList[0],
    }
    setIsLoading(true);
    const res = await ApiQueryLaborReport(removeNullUndefinedFields(reqParams))
    setIsLoading(false);
    if (res.code !== 0) return;
    const { datas = [] } = res.data;
    setTableData(datas);
  }
  const onDateRangeChange = (dates) => {
    const dateRangeType = rangePresets.find((v) => {
      return (v.value[0].isSame(dates[0])) && (v.value[1].isSame(dates[1]))
    })?.key ?? DATE_RANGE_TYPE.CUSTOM
    setParams(({ ...params, date: dates, dateRangeType }))
    fetchTableData();
  };

  const handleEmployeeChange = (val) => {
    setParams({ ...params, employeeIdList: val ? [val] : undefined });
    fetchTableData();
  }

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }

  const handleTableChange = (_pagination, _, sorter) => {
    fetchTableData();
  }

  const reload = () => {
    fetchTableData();
  }
  // region 导出相关
  const handleExport = async () => {
    setIsExporting(true)
    const { date, ...curParams } = getParams();
    const reqParams = {
      startTime: date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: date[1].format('YYYY-MM-DD 23:59:59'),
      employeeId: !curParams.employeeIdList || curParams.employeeIdList?.length === 0 ? '' : curParams.employeeIdList[0],
    }
    try {
      const resp: any = await ApiExportLaborSummary(reqParams);
      exportTaskId = resp?.data?.exportTaskId ?? null;
      if (resp?.code !== 0 || !exportTaskId) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        return;
      }
      getExportResult();
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
    }
  }
  const getExportResult = async () => {
    try {
      const resp: any = await ApiApaasQueryExportResult({ params: { req: { exportTaskId } } });
      if (resp.code !== 0) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        stopExport();
        return;
      }
      const data = resp.data;
      if (data?.result && data?.url) {
        // 开启下载
        downloadFile(data.url);
        message.success(i18n.t('pc_payout_report_successfully_downloaded'))
        setIsExporting(false);
        exportTaskId = null;
      } else {
        const id = setTimeout(async () => {
          getExportResult();
        }, 2000);
        setTimeoutId(id);
      }
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
      stopExport();
    }
  }
  const stopExport = () => {
    if (intervalId !== null) {
      clearTimeout(intervalId);
      setTimeoutId(null);
    }
  }
  return (
    <div className="labor-summary-wrap">
      <div className="m-title">
        <div className="title">{ i18n?.t('overseas_peppr_entrance_laborsummary') }</div>
        <div className="action-wrap">
          <Button type="primary" disabled={tableData.length === 0} loading={ isExporting } onClick={ () => handleExport() }>{ i18n.t('export_pc') }</Button>
        </div>
      </div>
      <Spin size="large" spinning={isLoading}>
        <div className="main-wrap">
          <div className="filter-wrap">
            <div className="left-wrap">
              <PepprDatePicker value={params.date} onChange={onDateRangeChange} today={today} setPresets={setRangePresets} />
              <Select
                className="employee-select"
                showSearch
                optionFilterProp="label"
                options={employeeAllList}
                value={params.employeeIdList}
                onChange={handleEmployeeChange}
                style={{ width: 200 }}
                allowClear
                placeholder={i18n.t('pc_employee_report_all_employee')}
              />
            </div>
            <div className="right-wrap">
              <Button type="link" onClick={reload}><SyncOutlined /></Button>
            </div>
          </div>
          {tableData.length > 0 && <div className="table-wrap">
            {tableData.map(itemData => {
              return (
                <div className='card-table' key={itemData.id}>
                  <div className="employee-name">{itemData.firstName}{itemData.lastName}</div>
                  <Table
                    className={isShowEmptyTable && 'empty-table'}
                    columns={tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType}
                    dataSource={itemData.detailDTOList}
                    rowKey={'id'}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    locale={{
                      emptyText: <Empty description={i18n.t('laborSummary_noRecords')} image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>,
                    }}
                    summary={() => (
                      <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
                        <Table.Summary.Cell index={0} align="center"><b>{i18n.t('pc_transaction_total')}</b></Table.Summary.Cell>
                        <Table.Summary.Cell index={1} align="center"><b>{itemData.totalLaborHours || '-'}</b></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="center"><b>{itemData.hourlyRateCombine || '-'}</b></Table.Summary.Cell>
                        <Table.Summary.Cell index={3} align="center"><b>{`$${itemData?.totalEstimatedTotalPay || 0}` || '-'}</b></Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  />
                  <div className="tips-box">
                    <div className="item">
                      <div className="title">{i18n.t('laborSummary_netSales')}</div>
                      <div className="info">
                        {itemData.employeeSales ? `$${parseFloat(itemData.employeeSales).toFixed(2)}` : 'N/A'}
                      </div>
                    </div>
                    <div className="item">
                      <div className="title">{i18n.t('laborSummary_cashTips')}</div>
                      <div className="info">
                        {itemData.cashTips ? `$${parseFloat(itemData.cashTips).toFixed(2)}` : 'N/A'}
                      </div>
                    </div>
                    <div className="item">
                      <div className="title">{i18n.t('laborSummary_cardTips')}</div>
                      <div className="info">
                        {itemData.cardTips ? `$${parseFloat(itemData.cardTips).toFixed(2)}` : 'N/A'}
                      </div>
                    </div>
                    <div className="item">
                      <div className="title">{i18n.t('laborSummary_totalTips')}</div>
                      <div className="info">
                        {itemData.totalTips ? `$${parseFloat(itemData.totalTips).toFixed(2)}` : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>}
        </div>
        {tableData.length === 0  && <Empty description={i18n.t('laborSummary_noRecords')} image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>}
      </Spin>
    </div>
  )
}