import './index.scss'
import { listServiceAreas, unarchiveServiceArea, archiveServiceArea, queryServiceAreaDetail, addServiceArea, saveServiceArea, sortServiceArea } from '@/request/ServiceArea'
// import { queryAllDiningOption } from '@/request/DiningOptions';
import React, { useEffect, useState } from 'react'
import { Button, Modal, Input, Form, message, Popover, Select } from 'antd';
import { globalState } from '@/stores';
import { PlusCircleOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { ArchivedPointIcon, ActivePointIcon, ArchivedIcon, ActiveIcon } from '../DiningOptions/component/IconComponent';
import { useBus } from 'react-bus';
import { SEARCH_FLOORPLAN } from '@/utils/BUS_TYPE';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
} from '@dnd-kit/sortable';
import { isStrictlyEmptyString } from '@/utils'

import DragTable, { DragHandle } from '@/Components/DragTable';

const { Option } = Select
interface IProps {
  activeKey: string,
  updateTableData: (val:boolean) => void
}
export default function ServiceArea (props: IProps) {
  const bus = useBus();
  const i18n = globalState.i18n;
  const [params, setParams] = useState(
    {
      req: {
        status: ['1']
      }
    }
  );
  const [ServiceAreaForm] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState<boolean>();
  const [tableData, setTableData] = useState([])
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [serviceAreaDetail, setServiceAreaDetail] = useState();
  // const [diningOptions, setDiningOptions] = useState([]);
  const DEFAULT_FILTER_TYPE = {
    'STATUS': ['1'],
  }
  const STATUS_FILTER = [{ text: i18n.t('active'), value: '1' }, { text: i18n.t('archived'), value: '2' }]
  const [statusFilter] = useState(STATUS_FILTER);
  const [filteredValue, setFilteredValue] = useState(DEFAULT_FILTER_TYPE.STATUS);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const archiveDiningOption = async (row) => {
    const params = {
      serviceArea: {
        ...row
      }
    };
    if (row.archived?.value == 1) {
      const response = await archiveServiceArea(params);
      if (response.code === 0) {
        message.success(response.message);
        fetchTableData();
      } else {
        message.error(response.message);
      }
    } else {
      const response = await unarchiveServiceArea(params);
      if (response.code === 0) {
        message.success(response.message);
        fetchTableData();
      } else {
        message.error(response.message);
      }
    }
  }

  const openServiceAreaModal = async () => {
    setModalOpen(true);
    setIsEdit(false);
    // const { data: list } = await queryAllDiningOption({
    //   status: ['1']
    // });
    // ServiceAreaForm.setFieldValue('diningOption', [list?.filter(f => f.defaultDisplay.tableService)[0].id]);
    // setDiningOptions(list);
  }

  const handleOk = async () => {
    const res = await ServiceAreaForm.validateFields(['areaName', 'diningOption']) ?? {};
    const data = !isEdit ? await addServiceArea({
      serviceArea: {
        areaName: res['areaName'],
        // diningOption: {
        //   id: res.diningOption
        // }
      }
    }) : await saveServiceArea({
      serviceArea: {
        ...serviceAreaDetail ?? {},
        areaName: res.areaName,
        // diningOption: {
        //   id: res.diningOption
        // }
      }
    }) ?? {};
    if (data?.code === 0) {
      setModalOpen(false);
      ServiceAreaForm.resetFields();
      message.success(data?.message);
      fetchTableData();
    }
  }

  const handleCancel = () => {
    setModalOpen(false);
    ServiceAreaForm.resetFields();
  }

  const handleFormInputBlur = (form, key) => {
    form.validateFields([key]);
  }

  const fetchTableData = async () => {
    setLoading(true)
    const { data } = await listServiceAreas(params);
    setLoading(false)
    setTableData(data);
  }

  const handleTableChange = (_, filters) => {
    setFilteredValue(filters.Status);
    setParams(
      {
        req: {
          status: filters.Status ?? []
        }
      }
    )
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const uuid = tableData.find(f => f.uuid === active.id).uuid;
      const targetSort = tableData.find(f => f.uuid === over.id).displayOrder;
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.uuid === active?.id);
        const overIndex = prevState.findIndex((record) => record.uuid === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      sortServiceArea({
        serviceArea: {
          uuid,
          targetSort
        }
      }).then(res => {
        if (res.code === 0) {
          message.success(res.message);
          fetchTableData();
        } else {
          message.error(res.message);
        }
      })
    }
  };

  const editServiceArea = async (row) => {
    setIsEdit(true);
    setModalOpen(true);
    const params = {
      serviceAreaDTO: {
        ...row
      }
    };
    const { data } = await queryServiceAreaDetail(
      params
    ) ?? {};
    // const { data: list } = await queryAllDiningOption({
    //   status: ['1']
    // });
    // console.log('list', list);

    ServiceAreaForm.setFieldsValue(data)
    ServiceAreaForm.setFieldValue('diningOption', [data?.diningOption?.id]);
    setServiceAreaDetail(data);
    // setDiningOptions(list);
  }

  const tableColumns: any = [
    { key: 'sort', align: 'center', width: 50, render: () => <DragHandle/> },
    {
      key: 'areaName', title: i18n?.t('serviceArea_service_area_name'), align: 'right', width: 50,
      render: (_, row) => {
        return (
          <div>
            {row?.areaName}
          </div>
        )
      }
    },
    {
      dataIndex: 'Status', key: 'Status', title: i18n.t('status'), width: 50, align: 'center',
      defaultFilteredValue: DEFAULT_FILTER_TYPE.STATUS,
      filteredValue: filteredValue,
      filters: statusFilter,
      filterResetToDefaultFilteredValue: true,
      render: (_, row) => {
        return (
          <div>
            {row?.archived.value === '1' ? <span><ActivePointIcon /> Active</span> : <span><ArchivedPointIcon /> Archived</span>}
          </div>
        )
      }
    },
    {
      key: 'actions', title: i18n.t('table_actions'), width: 100, align: 'center',
      render: (_, row) => {
        return (
          <div >
            <Button type="link" disabled={row.archived.value == '2'} onClick={() => {
              bus.emit(SEARCH_FLOORPLAN, row.id);
            }}>
              { i18n?.t('frontOfHouse_pc_edit_table') }
            </Button>
            <Popover content={
              <>
                <div style={{ 'display': 'flex' }}>
                  <Button type="link" onClick={() => {
                    editServiceArea(row);
                  }}>
                    <EditOutlined /> {i18n.t('edit_1')}
                  </Button>
                  <Button type="link" onClick={() => {
                    archiveDiningOption(row);
                  }}>
                    {
                      row?.archived.value === '1' ?
                        <div style={{ 'display': 'flex', 'alignItems': 'center' }}><ActiveIcon />{<span style={{ 'marginLeft': '5px' }}>{i18n?.t?.('archive')}</span>}</div> :
                        <div style={{ 'display': 'flex', 'alignItems': 'center' }}><ArchivedIcon />{<span style={{ 'marginLeft': '5px' }}>{i18n?.t?.('unarchived')}</span>}</div>
                    }
                  </Button>
                </div>
              </>
            }>
              <Button type="link" icon={<MoreOutlined />}></Button>
            </Popover>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    if (props.activeKey === '1') {
      fetchTableData();
    }
  }, [props.activeKey, params])
  useEffect(() => {
    // 检查tableData中是否有数据且数据至少有一个的archived.value为1
    // 如果有，则更新props.updateTableData，如果没有，则不更新
    if (tableData.length > 0) {
      const hasArchived = tableData.some(row => row.archived.value === '1');
      props.updateTableData(hasArchived);
    } else {
      props.updateTableData(false);
    }
  }, [tableData])

  return (
    <div>
      <div className="service-area-content">
        <div className="service-area-content-tabpane">
          <Button type="primary" icon={<PlusCircleOutlined />}  onClick={openServiceAreaModal}>
            { i18n?.t('add_new') }
          </Button>
        </div>
        <div className="dining-options-content-tableWrap">
          <DragTable
            loading={ loading }
            onDragEnd={onDragEnd}
            rowKey={'uuid'}
            columns={tableColumns}
            dataSource={tableData}
            onChange={handleTableChange}>
          </DragTable>
        </div>
      </div>
      <Modal title={i18n.t(isEdit ? 'frontOfHouse_pc_edit_service' : 'frontOfHouse_pc_add_service') } open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          {...layout}
          form={ServiceAreaForm}
          validateTrigger="onSubmit"
          labelAlign="left"
        >
          <Form.Item
            name="areaName"
            label={
              <>
                <div>Service Area Name</div>
                <span style={{ 'color': '#ff4d4f' }}> * </span>
              </>

            }
            rules={[
              () => ({
                validator: (_, value) => {
                  if (isStrictlyEmptyString(value)) {
                    return Promise.reject(`Please enter ${i18n?.t('serviceArea_service_area_name')}`);
                  }
                  if (value.length > 20) {
                    return Promise.reject(i18n?.t('frontOfHouse_pc_maximum', { length: 20 }))
                  }
                  return Promise.resolve()
                }
              }),
            ]}
          >
            {<Input required onBlur={() => { handleFormInputBlur(ServiceAreaForm, 'areaName') }} />}
          </Form.Item>
          {/* <Form.Item
            name="diningOption"
            label={
              <>
                <div>{ i18n?.t?.('frontOfHouse_diningOptions') }</div>
                <span style={{ 'color': '#ff4d4f' }}> * </span>
              </>
            }
            rules={[
              () => ({
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(`Please select ${ i18n?.t?.('frontOfHouse_diningOptions') }`);
                  }
                  return Promise.resolve()
                }
              }),
            ]}>
            <Select
              style={{ width: '100%' }}
              optionLabelProp="label"
            >
              {   diningOptions.map((ele, index) => (
                <Option value={ele.id} label={ele.name} key={ele.id}>
                  <div>
                    {ele.name}
                  </div>
                </Option>
              ))
              }
            </Select>
          </Form.Item> */}
        </Form>
      </Modal>
    </div>
  )
}