import React from 'react';
import './TextLineNew.scss';

type IFontSize = 'sm' | 'md' | 'lg' | string

type IFontStyle = 'normal' | 'italic'

type IFontWeight = 'default' | 'bold'

type IMarginBottom = 'none' | 'wide' | 'huge'

type ITextLineAttrStyle = {
  fontSize: IFontSize,
  color: string,
  fontStyle: IFontStyle,
  fontWeight: IFontWeight,
  marginBottom: IMarginBottom
}

interface ITextLineType{
  text: string,
  style?: Partial<ITextLineAttrStyle>
}

const TextLineNew = (props: ITextLineType) => {
  const {
    style = {
      fontSize: 'sm',
      color: '',
      fontStyle: 'normal',
      fontWeight: 'default',
      marginBottom: 'none'
    }
  } = props;

  const lineBreakSymbol = '\n'
  const LineBreak = (props: { text: string }) => {
    const lines = props?.text?.split(lineBreakSymbol).map((line, index) => (
      <div key={index} style={line ? {} : { height: 26 }}>{line}</div>
    ));
    return <div>{lines}</div>;
  }

  const {
    color,
    fontStyle = 'normal',
    fontSize = 'sm',
    fontWeight = 'default',
    marginBottom = 'none'
  } = style || {};

  const fontSizeObj: Record<IFontSize, 18 | 22 | 28> = {
    'sm': 18,
    'md': 22,
    'lg': 28
  }

  const marginBottomObj: Record<IMarginBottom, '0' | '16' | '24'> = {
    'none': '0',
    'wide': '16',
    'huge': '24'
  }

  const fontWeightObj: Record<IFontWeight, 400 | 700> = {
    'default': 400,
    'bold': 700
  }

  return (
    <div
      className={`
        preview-ui-text-line-new
      `}
      style={{
        color,
        fontStyle,
        fontSize: fontSizeObj[fontSize] || `${fontSize}px`,
        fontWeight: fontWeightObj[fontWeight],
        marginBottom: `${marginBottomObj[marginBottom]}px`
      }}
    >
      <LineBreak text={ props.text }/>
    </div>
  )
}

export default TextLineNew;