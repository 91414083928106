import React, { useEffect, useState } from 'react';
import { Form, Button, Radio, Table, Divider, InputNumber, message, Spin } from 'antd'
import { ColumnsType } from 'antd/es/table';
import { toRes } from '@/utils';
import { ApiQueryTipConfigVO, ApiSaveTipConfigVO } from '@/request/api';
import './index.scss';
import { globalState } from '@/stores';
interface DataType {
    defaultValue: boolean;
    tip: string;
    SYS$META$URI: string;
    tipLevel: string;
    key: number;
}
const getColumns = (i18n:any, isEdit:any, inputType:any, handleChange:any): ColumnsType<any> => [
  {
    title: i18n.t('amount_pc_tipLevel'),
    dataIndex: 'tipLevel',
  },
  {
    title: i18n.t('amount_pc_percentageOrDollor'),
    dataIndex: 'tip',
    render: (text: string, record: any) => {
      if (record.tipLevel === 'Custom' || record.tipLevel === 'None') {
        return null;
      }
      // 根据是否处于编辑状态和tipConfig的值，返回不同的组件或文本
      if (isEdit) {
        const inputProps = {
          onChange: (value) => handleChange({ ...record, tip: value }),
          value: text ? text : 0,
          step: '1.00',
          min: 0,
          precision: 2,
          style: { width: '200px' }
        };

        return inputType === '1' ?
          <InputNumber {...inputProps} max={100} addonAfter="%" /> :
          <InputNumber {...inputProps} prefix="$" />;
      } else {
        return inputType === '1' ? text + '%' : '$' + text;
      }
    },
  },
  {
    title: i18n.t('amount_pc_default'),
    dataIndex: 'defaultValue',
    render: (text: boolean, record: any) => {
      if (isEdit) {
        return <Radio onChange={(e) => handleChange({ ...record, defaultValue: e.target.checked })} checked={text} value={text}></Radio>
      } else {
        return text ? i18n.t('yes') : i18n.t('no')
      }
    }
  },
]
const DEFAULT_DATA = {
  id: '',
  tipLevels: [],
  name: '',
  tipTimingType: {
    value: '',
    displayName: ''
  },
  supportNoTips: false,
  tipType: {
    value: '',
    displayName: ''
  },
  tipDiscountType: {
    value: '',
    displayName: ''
  }
}
export default function tips () {
  const { i18n } = globalState;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>('');
  const [form] = Form.useForm();
  const [initData, setInitData] = useState(DEFAULT_DATA);
  const [tipLevelsData, setTipLevelsData] = useState([]);
  const [tipConfig, setTipConfig] = useState(DEFAULT_DATA)
  const defaultRadioData = {
    tipTimingType: [
      { value: '1', displayName: i18n.t('rms_overseas_operation_configuration_frontofhouse_TaxTimingType_BEFORE') },
      { value: '2', displayName: i18n.t('rms_overseas_operation_configuration_frontofhouse_TaxTimingType_AFTER') },
    ],
    tipDiscountType: [
      { value: '1', displayName: i18n.t('rms_overseas_operation_configuration_frontofhouse_DiscountTimingType_BEFORE') },
      { value: '2', displayName: i18n.t('rms_overseas_operation_configuration_frontofhouse_DiscountTimingType_After') },
    ],
    tipType: [
      { value: '1', displayName: i18n.t('rms_overseas_operation_configuration_frontofhouse_TipConfigType_Percent') },
      { value: '2', displayName: i18n.t('rms_overseas_operation_configuration_frontofhouse_TipConfigType_Fixed') },
    ]
  }
  const handleChange = (row: DataType) => {
    let newData = []
    if (inputType === initData.tipType.value) {
      newData = tipConfig.tipLevels.map(item => {
        if (row.defaultValue) {
          item.defaultValue = false
        }
        if (row.key === item.key) {
          item = { ...row }
        }
        return item
      });
      setTipConfig((prev) => ({
        ...prev,
        tipLevels: newData
      }));
    } else {
      newData = tipLevelsData.map(item => {
        if (row.defaultValue) {
          item.defaultValue = false
        }
        if (row.key === item.key) {
          item = { ...row }
        }
        return item
      });
      console.log(newData)
      setTipLevelsData(newData);
    }
  };
  const queryTipConfigVO = async () => {
    setLoading(true)
    try {
      const [error, data] = await toRes(ApiQueryTipConfigVO())
      if (!error) {
        if (data.tipTimingType == null) {
          data.tipTimingType = { value: '1', displayName: i18n.t('rms_overseas_operation_configuration_frontofhouse_TaxTimingType_BEFORE') }
        }
        if (data.tipDiscountType == null) {
          data.tipDiscountType = { value: '1', displayName: i18n.t('rms_overseas_operation_configuration_frontofhouse_DiscountTimingType_BEFORE') }
        }
        if (data.tipType == null) {
          data.tipType = { value: '1', displayName: i18n.t('rms_overseas_operation_configuration_frontofhouse_TipConfigType_Percent') }
        }
        setTipConfig(data)
        setInitData(data)
        initFormData(data)
        setInputType(data.tipType.value)
        setTipLevelsData(data.tipLevels.map(item => {
          return { ...item, tip: item.tipLevel === 'Custom' || item.tipLevel === 'None' ? null : '0' }
        }))
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }
  const initFormData = (data:any) => {
    form.setFieldsValue({
      ...data,
      tipTimingType: data.tipTimingType.value,
      tipType: data.tipType.value,
      tipDiscountType: data.tipDiscountType.value,
    });
  }
  const saveTipConfigVO = async () => {
    setLoading(true)
    const params = { tipConfig: { ...tipConfig } }
    if (inputType !== initData.tipType.value) {
      params.tipConfig.tipLevels = tipLevelsData
    }
    const [error] = await toRes(ApiSaveTipConfigVO({ params }))
    setLoading(false)
    if (!error) {
      setIsEdit(false)
      message.success('success')
      queryTipConfigVO()
    }
  }
  const cancelEdit = () => {
    setIsEdit(false)
    setTipConfig(initData)
    setInputType(initData.tipType.value)
    initFormData(initData)
  }
  const onValuesChange = (changedValues) => {
    const arr = ['tipTimingType', 'tipDiscountType', 'tipType']
    const keyName = Object.keys(changedValues)[0]
    if (arr.includes(keyName)) {
      const newTipData = { ...tipConfig }
      newTipData[keyName] = defaultRadioData[keyName].find(obj => obj.value === form.getFieldValue(keyName))
      if (keyName === 'tipType') {
        setInputType(changedValues.tipType)
        // newTipData.tipLevels = newTipData.tipLevels.map(item => {
        //     return {...item, tip: ''}
        // })
      }
      console.log('表单修改了:', keyName, newTipData)
      setTipConfig(newTipData)
    }
  }
  const changeSupportNoTips = (e: any) => {
    const value = e.target.value;
    const NONE = {
      defaultValue: false,
      tip: '0',
      SYS$META$URI: 'rms.overseas.MerchantManagementConsole.FrontOfHouse.TipLevelVO',
      tipLevel: 'None',
      key: 4
    };
    setTipConfig(prev => {
      const idxNone = prev.tipLevels.findIndex(obj => obj.tipLevel === 'None');
      let newTipLevels = prev.tipLevels;
      if (value && idxNone === -1) {
        newTipLevels = [...newTipLevels, NONE];
      } else if (!value && idxNone !== -1) {
        newTipLevels = newTipLevels.filter(obj => obj.tipLevel !== 'None');
      }
      return {
        ...prev,
        supportNoTips: value,
        tipLevels: newTipLevels
      };
    });
    setTipLevelsData(prev => {
      const idxNone = prev.findIndex(obj => obj.tipLevel === 'None');
      let newTipLevels = [...prev];
      if (value && idxNone === -1) {
        newTipLevels = [...newTipLevels, NONE];
      } else if (!value && idxNone !== -1) {
        newTipLevels = newTipLevels.filter(obj => obj.tipLevel !== 'None');
      }
      return newTipLevels;
    })
  };
  useEffect(() => {
    queryTipConfigVO()
  }, [])
  return (
    <div className="tips">
      <div className="title">Tips</div>
      <Divider />
      <div className="btn-box" style={{ textAlign: 'right' }}>
        {isEdit ? (
          <>
            <Button onClick={cancelEdit} style={{ marginRight: '16px' }}>
              {i18n.t('cancel')}
            </Button>
            <Button onClick={saveTipConfigVO} loading={loading} type="primary">
              {i18n.t('save')}
            </Button>
          </>
        ) : (
          <Button onClick={() => setIsEdit(true)} type="primary">
            {i18n.t('edit_1')}
          </Button>
        )}
      </div>
      <Spin spinning={loading}>
        <Form
          className="form-box"
          form={form}
          labelWrap
          size="large"
          labelAlign="left"
          labelCol={{ flex: '210px' }}
          initialValues={tipConfig}
          onValuesChange={onValuesChange}
        >
          <Form.Item label={i18n.t('amount_pc_tip_calculations_taxes')} name="tipTimingType">
            {isEdit ? <Radio.Group>
              {defaultRadioData.tipTimingType.map(item => <Radio value={item.value} key={item.value}>{item.displayName}</Radio>)}
            </Radio.Group> : tipConfig.tipTimingType.displayName}
          </Form.Item>
          <Form.Item label={i18n.t('amount_pc_tip_calculations_discount')} tooltip={i18n.t('amount_pc_tip_calculations_hovertitle')} name="tipDiscountType">
            {isEdit ? <Radio.Group>
              {defaultRadioData.tipDiscountType.map(item => <Radio value={item.value} key={item.value}>{item.displayName}</Radio>)}
            </Radio.Group> : tipConfig.tipDiscountType.displayName}
          </Form.Item>
          <Form.Item label={i18n.t('no_tips')} name="supportNoTips">
            {isEdit ? <Radio.Group onChange={(e) => changeSupportNoTips(e)}>
              <Radio value={true} key={1}>{i18n.t('yes')}</Radio><Radio value={false} key={0}>{i18n.t('no')}</Radio>
            </Radio.Group> : tipConfig.supportNoTips ? i18n.t('yes') : i18n.t('no')}
          </Form.Item>
          <Form.Item label={i18n.t('amount_pc_tips_type')} name="tipType">
            {isEdit ? <Radio.Group>
              {defaultRadioData.tipType.map(item => <Radio value={item.value} key={item.value}>{item.displayName}</Radio>)}
            </Radio.Group> : tipConfig.tipType.displayName}
          </Form.Item>
          {inputType === initData.tipType.value ? <Table
            columns={getColumns(i18n, isEdit, inputType, handleChange)}
            dataSource={tipConfig.tipLevels}
          /> : <Table
            columns={getColumns(i18n, isEdit, inputType, handleChange)}
            dataSource={tipLevelsData}
          />}
        </Form>
      </Spin>
    </div>
  );

}