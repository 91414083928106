export enum REFUND_STATUS_TYPE {
  NA = 0,
  PARTILA_REFUNDED = 1,
  REFUNDED = 2
}

export const REFUND_STATUS_MAP = {
  [REFUND_STATUS_TYPE.NA]: 'N/A',
  [REFUND_STATUS_TYPE.PARTILA_REFUNDED]: 'Partial Refunded',
  [REFUND_STATUS_TYPE.REFUNDED]: 'Refunded'
}

export enum PAYMENT_STATUS_TYPE {
  VOIDED = 1,
  DECLINED = 2,
  PROCESSING = 3,
  AUTHORIZED = 4,
  CAPTURED = 5,
}

export const PAYMENT_STATUS_MAP = {
  [PAYMENT_STATUS_TYPE.VOIDED]: 'Voided',
  [PAYMENT_STATUS_TYPE.DECLINED]: 'Declined',
  [PAYMENT_STATUS_TYPE.PROCESSING]: 'Processing',
  [PAYMENT_STATUS_TYPE.AUTHORIZED]: 'Authorized',
  [PAYMENT_STATUS_TYPE.CAPTURED]: 'Captured'
}