import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Card, Form, Radio, Switch } from 'antd'
import { globalState } from '@/stores';
import './ChannelAvailability.scss'

interface IProps {
  editInfo: {
    visibleChannelPOS: boolean
    onlineOrdering: boolean
  },
  pricingType?: {
    displayName: string,
    value: string
  }
}

const ChannelAvailability = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState;
  const {
    editInfo: { visibleChannelPOS, onlineOrdering }
  } = props
  const [onlineOrderingDisabled, setOnlineOrderingDisabled] = useState<boolean>(false);
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => {
    return {
      form
    };
  }, []);

  const changeRadio = (val) => {
    form.setFieldsValue({ 'visibleChannelPOS': val });
  }

  // useEffect(() => {
  //   form.setFieldsValue({
  //     'visibleChannelPOS': visibleChannelPOS ?? true
  //   });
  // }, [visibleChannelPOS])

  // useEffect(() => {
  //   form.setFieldsValue({
  //     'onlineOrdering': onlineOrdering
  //   });
  // }, [onlineOrdering])

  useEffect(() => {
    form.setFieldsValue({
      'visibleChannelPOS': visibleChannelPOS ?? true,
      'onlineOrdering': onlineOrdering ?? false
    });
    console.log('form', form.getFieldsValue(), onlineOrdering)
  }, [visibleChannelPOS, onlineOrdering])

  useEffect(() => {
    const { pricingType } = props;
    if (pricingType) {
      const state = pricingType.value === 'openPrice';
      setOnlineOrderingDisabled(state);
      if (state) {
        form.setFieldValue('onlineOrdering', false);
      }
    }
  }, [props.pricingType])

  return (
    <div className='sub-menus-channel-availability'>
      <Card title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 10 }}>{i18n.t('visible_channel')}</span>
        </div>
      } >
        <Form form={form} layout="vertical">

          <div>POS</div>
          <Form.Item name="visibleChannelPOS">
            <Radio.Group onChange={(e) => changeRadio(e.target.value) }>
              <Radio value={true}>{ i18n?.t('yes') }</Radio>
              <Radio value={false}>{ i18n?.t('no') }</Radio>
            </Radio.Group>
          </Form.Item>

          <div>{ i18n?.t('menu_online_ordering') }</div>
          <Form.Item name="onlineOrdering">
            <Radio.Group disabled={onlineOrderingDisabled}>
              <Radio value={true}>{ i18n?.t('yes') }</Radio>
              <Radio value={false}>{ i18n?.t('no') }</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
})

export default ChannelAvailability