import './index.scss';
import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { Button, Input, Select, Form, Spin, Radio, Table, Modal } from 'antd';
import { getActiveRoles } from '@/request/Role';
import { isEmpty, verifyEmail } from '@/utils/index';
import {
  createEmployee,
  editEmployee,
  getAccessCode,
  getEmployeeDetail,
  IEmployeeDetail,
} from '@/request/EmployeeCreate';
import { message, Checkbox, InputNumber } from 'antd';
import { EDIT_EMPLOYEES } from '@/utils/BUS_TYPE';
import { DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;

export default function EmployeeCreate (props) {
  const [employeeForm] = Form.useForm();
  const [jobList, setJobList] = useState([]);
  const [employeeDetail, setEmployeeDetail] = useState<IEmployeeDetail>();
  const [dashboardAccess, setDashboardAccess] = useState<boolean>(false);
  const [bindStatus, setBindStatus] = useState(
    employeeForm.getFieldValue('bindStatus')
  );
  const [jobFormData, setJobFormData] = useState([
    { roleId: null,
      hourlyRate: 0, }
  ]);
  const [isDelRoleModalVisible, setIsDelRoleModalVisible] =
    useState<boolean>(false);

  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);
  const [currentRoleIndex, setCurrentRoleIndex] = useState<number | null>(null);

  const i18n = globalState.i18n;
  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 4 },
  };
  const employeeManagerTag = {
    operationManager: 1,
    restaurantManager: 2,
  };
  const managerList = [
    employeeManagerTag.operationManager,
    employeeManagerTag.restaurantManager,
  ];

  const jobFormColumns = [
    {
      title: (
        <div>
          <span>{i18n.t('Dasdboard_team_roles_edit_role')}</span>
          <span style={{ color: '#ff4d4f' }}> * </span>
        </div>
      ),
      dataIndex: 'roleId',
      width: '60%',
      render: (_, record, index) => (
        <Form.Item
          name={['roles', index, 'roleId']}
          rules={[{ required: true, message: i18n.t('dashboard_team_employee_select_role') }]}
        >
          <Select
            value={record.roleId}
            style={{ width: '380px' }}
            optionLabelProp="label"
            disabled={managerList.includes(employeeDetail?.employee?.tag)}
            placeholder={
              managerList.includes(employeeDetail?.employee?.tag)
                ? i18n.t('pc_owner')
                : i18n.t('dashboard_team_employee_select_role')
            }
            allowClear
            onChange={(value) => handleRoleChange(value, index)}
          >
            {managerList.includes(employeeDetail?.employee?.tag) ? (
              <Option label={i18n?.t('pc_owner')} value={jobList[0]?.id}>
                <div>{i18n?.t('pc_owner')}</div>
              </Option>
            ) : (
              jobList.map((ele, index) => (
                <Option
                  value={ele.id}
                  label={ele.label}
                  key={index}
                  disabled={
                    selectedRoleIds.includes(ele.id) && ele.id !== record.roleId
                  }
                >
                  <div>{ele.label}</div>
                </Option>
              ))
            )}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: (
        <div>
          <span>{i18n.t('Dasdboard_team_roles_edit_hourly_rate')}</span>
        </div>
      ),
      dataIndex: 'hourlyRate',
      width: '40%',
      render: (_, record, index) => (
        <Form.Item name={['roles', index, 'hourlyRate']}>
          <div style={{ display: 'flex' }}>
            <InputNumber
              value={record.hourlyRate}
              addonBefore="$"
              addonAfter={i18n.t('Dashboard_team_roles_edit_hourly_rate_hour')}
              min={0}
              style={{ width: '100%' }}
              precision={2}
              onChange={(value) => {
                const newJobFormData = [...jobFormData];
                newJobFormData[index].hourlyRate = value;
                setJobFormData(newJobFormData);
              }}
            />
            {!managerList.includes(employeeDetail?.employee?.tag) && jobFormData.length > 1 &&  (
              <>
                <DeleteOutlined
                  style={{ color: '#2563eb', marginLeft: 5 }}
                  onClick={() => deleteRole(record.roleId, index)}
                />
                <Modal
                  title={i18n.t('dashboard_team_employee_delete_role')}
                  open={isDelRoleModalVisible}
                  onOk={handleDeleteRole}
                  onCancel={closeDelRoleModal}
                  centered
                  footer={[
                    <Button key="cancel" onClick={closeDelRoleModal}>
                      {i18n?.t('cancel')}
                    </Button>,
                    <Button
                      key="confirm"
                      type="primary"
                      onClick={handleDeleteRole}
                    >
                      {i18n.t('frontOfHouse_pc_delete')}
                    </Button>,
                  ]}
                >
                  <p>{i18n.t('dashboard_team_employee_delete_prompt')}</p>
                </Modal>
              </>
            )}
          </div>
        </Form.Item>
      ),
    },
  ];

  const handleRoleChange = (value, index) => {
    const newJobFormData = [...jobFormData];
    newJobFormData[index].roleId = value;
    setJobFormData(newJobFormData);
  };

  const addRole = () => {
    const newRole = {
      roleId: null,
      hourlyRate: 0,
    };
    setJobFormData([...jobFormData, newRole]);
  };

  const deleteRole = (roleId: number, index: number) => {
    if (roleId) {
      showDeleteModal(index);
    } else {
      removeRoleAtIndex(index);
    }
  };

  const removeRoleAtIndex = (index: number) => {
    setJobFormData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const showDeleteModal = (index: number) => {
    setCurrentRoleIndex(index);
    setIsDelRoleModalVisible(true);
  };

  const closeDelRoleModal = () => {
    setIsDelRoleModalVisible(false);
  };

  const handleDeleteRole = () => {
    if (currentRoleIndex !== null) {
      removeRoleAtIndex(currentRoleIndex);
    }
    setIsDelRoleModalVisible(false);
  };

  const getEmployeeFormInitial = async () => {
    let obj = {};
    if (!!props.id) {
      const detail = (await getEmployeeDetail(props.id)) || {};
      setDashboardAccess(!!detail?.employee?.dashboardAccess);
      setEmployeeDetail(detail);
      const { employee, roles, employeeCard } = detail;
      obj = {
        employee,
        roles,
        employeeCard,
      };
    }
    return obj;
  };

  const handleCreateAccessCode = async () => {
    const data = await getAccessCode();
    employeeForm.setFieldValue('accessCode', data);
  };

  useEffect(() => {
    getActiveRoles().then((res) => {
      const jobList = res.map((m) => ({
        label: m.roleName,
        id: m.roleId,
      }));
      setJobList(jobList);
    });
    if (props.id) {
      getEmployeeFormInitial().then((res: any) => {
        const { employee, roles, employeeCard } = res;
        employeeForm.setFieldsValue({ ...employee, ...employeeCard });
        if (employee.tag === 1 || employee.tag === 2) {
          setJobList([
            ...jobList,
            ...roles.map((m) => ({
              label: m?.roleName,
              id: m?.roleId,
            })),
          ]);
        }

        const initJobFormData = roles.map((m) => ({
          roleId: m?.roleId || null,
          hourlyRate: m?.hourlyRate || 0,
        }));
        setJobFormData([...initJobFormData]);

        setBindStatus(employeeCard?.bindStatus);
        employeeForm.setFieldValue('accessCode', employee.accessCode);
      });
    }
  }, []);


  const handleCancel = () => {
    props.close();
  };

  const formatRequestParams = async () => {
    // 校验 jobFormData 中的 roleId
    jobFormData.forEach((job, index) => {
      if (job.roleId === null) {
        employeeForm.validateFields([['roles', index, 'roleId']]).catch((err) => {
          console.error('Validation error:', err);
        });
      }
    });
    const hasNullRoleId = jobFormData.some((job) => job.roleId === null);
    if (hasNullRoleId) {
      console.error('Validation error: roleId cannot be null');
      return false;
    }

    // 校验form
    const promiseArr = [
      employeeForm.validateFields([
        'firstName',
        'lastName',
        'phoneNumber',
        'email',
        'posLoginMethod',
        'accessCode',
      ]),
    ];

    try {
      const params = await Promise.all(promiseArr);
      return params;
    } catch (err) {
      console.error('Validation error:', err);
      if (err && err.errorFields) {
        err.errorFields.forEach((errorField) => {
          console.error(
            `Field: ${errorField.name}, Error: ${errorField.errors}`);
        });
      }
      return false;
    }
  };

  const handleSave = async () => {
    const params = await formatRequestParams();
    if (!params) {
      return;
    }

    const extractedData = jobFormData.map((job) => ({
      roleId: job.roleId,
      hourlyRate: job.hourlyRate,
    }));

    const createParams = {
      employee: {
        employeeId: props.id,
        ...params[0],
        dashboardAccess: +dashboardAccess,
      },
      roles: extractedData,
    } as any;
    let res = {};
    if (props.id) {
      res = await editEmployee(createParams);
    } else {
      res = await createEmployee(createParams);
    }
    if (res) {
      message.success(i18n?.t('menuTabList_pc_success'));
      props.close();
      props.bus.emit(EDIT_EMPLOYEES);
    }
  };

  const handleFormInputBlur = (form, key) => {
    form.validateFields([key]);
  };

  useEffect(() => {
    const newJobFormData = [...jobFormData];
    // 更新选中的 roleId 列表
    const newSelectedRoleIds = newJobFormData
      .map((job) => job.roleId)
      .filter((id) => id);
    setSelectedRoleIds(newSelectedRoleIds);
    employeeForm.setFieldsValue({ roles: newJobFormData });
  }, [jobFormData]);

  return (
    <Spin spinning={!!props.id && !employeeDetail?.employee.firstName}>
      <div className="employee-create-content">
        <div className="employee-create-content-box">
          <span
            style={{ fontSize: '24px', fontWeight: '500', color: '#3d3d3d' }}
          >
            {!!props.id
              ? i18n?.t('employeeAdd_pc_edit_employee')
              : 'Add New employee'}
          </span>
          <div>
            <Button onClick={handleCancel}>{i18n?.t('cancel')}</Button>
            <Button
              type="primary"
              disabled={employeeDetail?.employee?.tag === 1}
              style={{ marginLeft: '12px' }}
              onClick={handleSave}
            >
              {i18n?.t('save')}
            </Button>
          </div>
        </div>
        <div className="employee-create-content-wrap">
          <div style={{ fontSize: '16px', fontWeight: '500' }}>
            {i18n?.t('personal_info')}
          </div>
          <div className="employee-create-content-employeeForm">
            <Form {...layout} form={employeeForm} validateTrigger="onSubmit">
              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('employeeAdd_pc_first_name')}</span>
                    <span style={{ color: '#ff4d4f' }}> * </span>
                  </div>
                }
                name="firstName"
                rules={[
                  () => ({
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(
                          i18n.t('please_enter_x', {
                            msg: i18n?.t('employeeAdd_pc_first_name'),
                          })
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {
                  <Input
                    required
                    onBlur={() => {
                      handleFormInputBlur(employeeForm, 'firstName');
                    }}
                  />
                }
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('last_name')}</span>
                    <span style={{ color: '#ff4d4f' }}> * </span>
                  </div>
                }
                name="lastName"
                rules={[
                  () => ({
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(
                          i18n.t('please_enter_x', {
                            msg: i18n?.t('last_name'),
                          })
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {
                  <Input
                    required
                    onBlur={() => {
                      handleFormInputBlur(employeeForm, 'lastName');
                    }}
                  />
                }
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('email_address')}</span>
                    {dashboardAccess && (
                      <span style={{ color: '#ff4d4f' }}> * </span>
                    )}
                  </div>
                }
                name="email"
                rules={[
                  {
                    validator (rule, value) {
                      if (!dashboardAccess && !value) {
                        return Promise.resolve();
                      }
                      if (!value) {
                        return Promise.reject(
                          i18n.t('please_enter_x', {
                            msg: i18n?.t('myAccount_pc_new_email'),
                          })
                        );
                      }
                      if (!verifyEmail(value)) {
                        return Promise.reject(i18n?.t('pc_login_valid_email'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {
                  <Input
                    disabled={!!employeeDetail?.employee?.accountId}
                    onBlur={() => {
                      handleFormInputBlur(employeeForm, 'email');
                    }}
                  />
                }
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('employeeAdd_pc_phone_number')}</span>
                  </div>
                }
                name="phoneNumber"
              >
                {<Input required />}
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="employee-create-content-wrap">
          <div style={{ fontSize: '16px', fontWeight: '500' }}>
            {i18n.t('dashboard_team_employee_account_access')}
          </div>
          <div className="employee-create-content-jobForm">
            <Form {...layout} form={employeeForm} validateTrigger="onSubmit">
              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('employeeAdd_pc_pos_access')}</span>
                    <span style={{ color: '#ff4d4f' }}> * </span>
                  </div>
                }
                name="accessCode"
                rules={[
                  () => ({
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(
                          i18n?.t('please_enter_x', {
                            msg: i18n?.t('employeeAdd_pc_pos_access'),
                          })
                        );
                      }
                      if (!/^\d{4}$/.test(value)) {
                        return Promise.reject(
                          i18n?.t('employee_create_numeric_code')
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                style={{
                  marginBottom: 0,
                }}
              >
                {
                  <Input
                    required
                    onBlur={() => {
                      handleFormInputBlur(employeeForm, 'accessCode');
                    }}
                    addonAfter={
                      <div
                        onClick={handleCreateAccessCode}
                        className="access-code-addon-after"
                      >
                        {i18n?.t('employeeAdd_pc_generate_code')}
                      </div>
                    }
                  />
                }
              </Form.Item>
              <div
                style={{
                  marginBottom: 0,
                }}
              >
                <div className="employee-create-content-employeeForm-tips">
                  {i18n?.t('employeeAdd_pc_passcode_tips')}
                </div>
              </div>
              <Form.Item
                name="dashboardAccess"
                style={{
                  marginBottom: 0,
                }}
              >
                <Checkbox
                  checked={dashboardAccess}
                  onChange={(val) => {
                    if (
                      !val.target?.checked &&
                      employeeForm.getFieldValue('email')
                    ) {
                      handleFormInputBlur(employeeForm, 'email');
                    }
                    setDashboardAccess(val?.target?.checked);
                    handleFormInputBlur(employeeForm, 'email');
                  }}
                />
                <span style={{ marginLeft: 10 }}>
                  {i18n.t('dashboard_team_employee_requires_dashboard_access')}
                </span>
              </Form.Item>
              {bindStatus === 1 && (
                <>
                  <Form.Item
                    label={
                      <div>
                        <span>
                          {i18n?.t('Dashboard_team_roles_edit_login_method')}
                        </span>
                        <span style={{ color: '#ff4d4f' }}> * </span>
                      </div>
                    }
                    name="posLoginMethod"
                    style={{
                      marginBottom: 0,
                    }}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(
                              i18n.t('please_enter_x', {
                                msg: i18n.t(
                                  'Dashboard_team_roles_edit_login_method'
                                ),
                              })
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={() => {
                        handleFormInputBlur(employeeForm, 'posLoginMethod');
                      }}
                    >
                      <Radio value={1}>
                        {i18n.t(
                          'Dasdboard_team_roles_edit_login_method_hybrid'
                        )}
                      </Radio>
                      <Radio value={2}>
                        {i18n.t(
                          'Dasdboard_team_roles_edit_login_method_card_only'
                        )}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <div className="employee-create-content-employeeForm-tips">
                    {i18n.t('Dasdboard_team_roles_edit_login_description')}
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>

        <div className="employee-create-content-wrap">
          <div style={{ fontSize: '16px', fontWeight: '500' }}>
            {i18n?.t('employeeAdd_pc_job_info')}
          </div>
          <div className="employee-create-content-jobForm">
            <Form form={employeeForm}>
              <Table
                columns={jobFormColumns}
                dataSource={jobFormData}
                pagination={false}
              />
            </Form>
            {!managerList.includes(employeeDetail?.employee?.tag) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <Button type="link" onClick={addRole}>
                  + {i18n.t('dashboard_team_employee_add_new_employee')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
}
