import React from 'react'
import TextLineNew from '../component/TextLineNew'
import './index.scss'
const TipSuggestAreaSection = () => {
  return <div className='tip-area'>
    <TextLineNew
      text='Tips'
      style={{
        fontSize: 'md',
      }}
    />
    <div className='tip-area-item'>
      <div className='tip-area-item-bracket'>[&nbsp;&nbsp;]</div>
      <TextLineNew text='15% (Tips $0.00, You pay $0.00)'/>
    </div>
    <div className='tip-area-item'>
      <div className='tip-area-item-bracket'>[&nbsp;&nbsp;]</div>
      <TextLineNew text='18% (Tips $0.00, You pay $0.00)'/>
    </div>
    <div className='tip-area-item'>
      <div className='tip-area-item-bracket'>[&nbsp;&nbsp;]</div>
      <TextLineNew text='20% (Tips $0.00, You pay $0.00)'/>
    </div>
  </div>
}
export default TipSuggestAreaSection;