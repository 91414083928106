import { RangeValueType } from '@/types';

export interface IParamsState {
  date: RangeValueType;
  employeeIdList: any[];
  sortByDescTime: boolean;
  operateTypeList: any[];
  dateRangeType: string;
}

export interface IPage {
  current: number;
  pageSize: number;
  pageNo: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

export enum TAB_TYPE {
  VOID = 'VOID',
  REFUND = 'REFUND',
  DISCOUNT = 'DISCOUNT'
}

export const TAB_TYPE_I18N = {
  [TAB_TYPE.VOID]: 'pc_employee_report_tabs_void',
  [TAB_TYPE.REFUND]: 'pc_employee_report_tabs_refund',
  [TAB_TYPE.DISCOUNT]: 'pc_employee_report_tabs_discount'
}

export enum OPERATE_TYPE {
  CANCEL_ORDER = 1,
  VOID_ITEM = 2,
  ENTIRE_DISCOUNT = 3,
  ITEM_DISCOUNT = 4,
  ENTIRE_REFUND = 5,
  ITEM_REFUND = 6,
  TAX_REFUND = 7,
  TIPS_REFUND = 8,
  ENTIRE_COMP = 9,
  ITEM_COMP = 10
}

export const TAB_OPERATE_FILTERS_MAP = {
  [TAB_TYPE.VOID]: [OPERATE_TYPE.CANCEL_ORDER, OPERATE_TYPE.VOID_ITEM],
  [TAB_TYPE.REFUND]: [OPERATE_TYPE.ENTIRE_REFUND, OPERATE_TYPE.ITEM_REFUND, OPERATE_TYPE.TAX_REFUND, OPERATE_TYPE.TIPS_REFUND],
  [TAB_TYPE.DISCOUNT]: [OPERATE_TYPE.ENTIRE_DISCOUNT, OPERATE_TYPE.ITEM_DISCOUNT, OPERATE_TYPE.ENTIRE_COMP, OPERATE_TYPE.ITEM_COMP]
}

export const OPERATE_TYPE_I18N = {
  [OPERATE_TYPE.CANCEL_ORDER]: 'pc_employee_report_cancel_order',
  [OPERATE_TYPE.VOID_ITEM]: 'pc_employee_report_void_item',
  [OPERATE_TYPE.ENTIRE_DISCOUNT]: 'pc_employee_report_entire_discount',
  [OPERATE_TYPE.ITEM_DISCOUNT]: 'pc_employee_report_item_discount',
  [OPERATE_TYPE.ENTIRE_REFUND]: 'pc_employee_report_entire_refund',
  [OPERATE_TYPE.ITEM_REFUND]: 'pc_employee_report_item_refund',
  [OPERATE_TYPE.TAX_REFUND]: 'pc_employee_report_tax_refund',
  [OPERATE_TYPE.TIPS_REFUND]: 'pc_employee_report_tips_refund',
  [OPERATE_TYPE.ENTIRE_COMP]: 'pc_employee_report_entire_comp',
  [OPERATE_TYPE.ITEM_COMP]: 'pc_employee_report_item_comp'
}

export interface IPage {
  current: number;
  pageSize: number;
  pageNo: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}
