import './index.scss';
// import Button from '@saas/saas-ui-pc-button'
import { SwapOutlined } from '@ant-design/icons'
import PersonalInfomation from './component/PersonalInfomation';
import { Button, Modal, Input, Form, message, Spin } from 'antd';
import { verifyEmail } from '@/utils/index'
import React, { useEffect, useState } from 'react';
import VerifyCode from './component/VerifyCode';
import { EyeInvisibleOutlined } from '@ant-design/icons'
import { ReactComponent as EyeTwoTone } from '@/assets/svg/EyeTwoTone.svg'
import { getLoginInfo, resetEmailByVerificationCode, updatePwdByLastPwd, getVerificationCode, doCheckPassword } from '@/request/Account'
import { checkPassword } from '@/utils/index'
import { globalState } from '@/stores';
import { useNavigate } from 'react-router-dom';

export default function MyAccount () {
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const { i18n } = globalState;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [modalStep, setModalStep] = useState<number>(0);
  const [infomation, setInfomation] = useState<any>({});
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [emailInfo, setEmailInfo] = useState<any>({});

  const initFormData = async () => {
    setLoading(true)
    try {
      await getLoginInfo().then(res => {
        const { empInfo } = res;
        setInfomation(empInfo);
      })
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const handleEmaillClick = () => {
    setIsModalVisible(true);
  }

  const handlePasswordClick = () => {
    setIsPasswordModalVisible(true);
  }


  const handleOk = async () => {
    if (modalStep === 0) {
      const r = await form.validateFields(['oldEmail', 'newEmail'])
      const res = await getVerificationCode({
        email: r.newEmail,
        type: 2
      })
      if (res) {
        form.resetFields();
        setEmailInfo({ ...r });
        setModalStep(modalStep + 1);
      }
    } else {
      const res = await resetEmailByVerificationCode({
        newEmail: emailInfo.newEmail,
        oldEmail: emailInfo.oldEmail,
        verificationCode: verifyCode
      })
      if (res) {
        message.success(i18n?.t('message_success'));
        setTimeout(() => {
          window.location.replace('/login');
        })
      }
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    if (modalStep === 0) {
      form.resetFields();
    } else {
      setModalStep(0);
    }
  }

  const handlePasswordModalOk = async () => {
    const r = await passwordForm.validateFields(['oldPassword', 'newPassword']);
    const res = updatePwdByLastPwd({
      accountId: infomation.accountId,
      oldPassword: r.oldPassword,
      newPassword: r.newPassword
    })
    if (res) {
      message.success(i18n?.t('message_success'));
      setTimeout(() => {
        window.location.replace('/login');
      })
    }
  }

  const handlePasswordModalCancel = () => {
    setIsPasswordModalVisible(false);
    passwordForm.resetFields();
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const getInitial = () => {
    return {
      oldEmail: infomation.email,
      newEmail: ''
    }
  }

  const handlePasswordFormChange = (value: any) => {
    const { oldPassword, newPassword } = value;
    if (oldPassword == newPassword) {
      // 检测是否与当前密码相同
    } else if (!/^(?![0-9]+$)(?![A-Za-z]+$)[0-9A-Za-z]{8,}$/.test(newPassword)) {
      // 检测正则是否符合

    } else {
      // 符合规则通过

    }
  }

  const oldPasswordCheckers = (_, value) => {
    if (!value) {
      return Promise.reject(i18n?.t('myAccount_pc_incorrect_password'));
    }
    return new Promise((resolve, reject) => {
      doCheckPassword({
        accountId: infomation.accountId,
        password: value
      }).then(res => {
        if (!res) {
          reject(i18n?.t('myAccount_pc_incorrect_password'));
        } else {
          resolve({});
        }
      })
    })
  }

  useEffect(() => {
    initFormData();
  }, [])

  return (
    <>
      <Spin spinning={loading} size='large'>
        <div className="account-container">
          <span className="account-container-title">
            My Account
          </span>
          <div className="account-container-divider"></div>
          <PersonalInfomation infomation={infomation}/>
          <div className="account-container-operate">
            <div className="operate-title">Login</div>
            <div className="operate-item">
              <span className="operate-item-label">Email</span>
              <span className="operate-item-content">{infomation.email}</span>
              <Button icon={ <SwapOutlined/> } type="link" onClick={ handleEmaillClick } style={ { color: '#f29d39' } }>Change Email</Button>
            </div>
            <div className="operate-item">
              <span className="operate-item-label">
                {i18n?.t('password')}
              </span>
              <span className="operate-item-content">Changing your password will log you out of your account.</span>
              <Button icon={ <SwapOutlined/> } type="link" onClick={ handlePasswordClick } style={ { color: '#f29d39' } }>Change Password</Button>
            </div>
          </div>
        </div>
      </Spin>
      {/* 修改邮箱的弹窗 */}
      <Modal
        title={
          <div>
            <div style={{ 'fontSize': '20px' }}>
              { i18n?.t(modalStep === 0 ? 'myAccount_pc_change_email' : 'myAccount_pc_verify_code')}
            </div>
            {
              modalStep === 0 && <div className="email-subtitle">
                {i18n?.t('myAccount_pc_change_email_tips')}
              </div>
            }
          </div>
        }
        closable={false}
        open={isModalVisible}
        onOk={handleOk}
        okText={ i18n?.t(modalStep === 0 ? 'save' : 'confirm') }
        onCancel={handleCancel}
        cancelText={ i18n?.t('cancel') }
        wrapClassName="account-model-wrap"
        width={'580px'}>
        {
          modalStep === 0 ?
            <Form
              { ...layout }
              form={form}
              // setAsyncInitialValues={async () => await getInitial()}
              initialValues={ getInitial() }
              validateTrigger="onSubmit"
            >
              <Form.Item
                label={
                  <div>
                    <span>{ i18n?.t('myAccount_pc_current_email') }</span>
                  </div>
                }
                name="oldEmail"
              >
                { <Input style={{ 'width': 250 }} onBlur={() => { form.validateFields(['oldEmail']); }} disabled/> }
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span style={{ 'color': '#ff4d4f' }}>* </span>
                    <span>{ i18n?.t('myAccount_pc_new_email') }</span>
                  </div>
                }
                name="newEmail"
                rules={[
                  () => ({
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject('Please enter New Email Address');
                      }
                      if (!verifyEmail(value)) {
                        return Promise.reject('Please enter a valid email address');
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                { <Input style={{ 'width': 250 }} onBlur={ () => { form.validateFields(['newEmail']); } }/> }
              </Form.Item>
            </Form>
            :
            <div className="verify-code-form">
              <div className="verify-code-form-label">
                { i18n?.t('myAccount_pc_verify_code') }:
              </div>
              <div className="verify-code-form-content">
                <VerifyCode verifyCodeCallBack={setVerifyCode} resendCallBack={ () => {
                  getVerificationCode({
                    email: emailInfo.newEmail,
                    type: 2
                  })
                } }/>
              </div>
            </div>
        }
      </Modal>

      {/* 修改密码的弹窗 */}
      <Modal
        title={
          <div>
            <div style={{ 'fontSize': '20px' }}>
              {i18n?.t('change_password')}
            </div>
            <div className="email_subtitle">
              {i18n?.t('myAccount_pc_change_password_tips')}
            </div>
          </div>
        }
        closable={false}
        open={isPasswordModalVisible}
        onOk={handlePasswordModalOk}
        okText={ i18n?.t('save') }
        onCancel={handlePasswordModalCancel}
        cancelText={ i18n?.t('cancel') }
        wrapClassName="account-model-wrap"
        width={'580px'}>
        <Form
          { ...layout }
          form={passwordForm}
          onChange={ handlePasswordFormChange }
          validateTrigger="onSubmit"
        >
          <Form.Item
            label={
              <div>
                <span>{ i18n?.t('myAccount_pc_current_password') }</span>
              </div>
            }
            name="oldPassword"
            validateTrigger="onBlur"
            rules={[
              () => ({
                validator: oldPasswordCheckers
              })
            ]}
          >
            { <Input.Password style={{ 'width': 250 }} iconRender={ (visible: any) => visible ? <EyeTwoTone style={{ 'color': 'black' }}/> : <EyeInvisibleOutlined style={{ 'color': 'black' }}/> }/> }
          </Form.Item>

          <Form.Item
            label={
              <div>
                <span>{ i18n?.t('new_password') }</span>
              </div>
            }
            name="newPassword"
            rules={[
              () => ({
                validator: (_, value) => checkPassword(value, i18n),
              }),
            ]}
          >
            { <Input.Password style={{ 'width': 250 }} onBlur={ () => { passwordForm.validateFields(['newPassword']); } } iconRender={ (visible: any) => visible ? <EyeTwoTone style={{ 'color': 'black' }}/> : <EyeInvisibleOutlined style={{ 'color': 'black' }}/> }/> }
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}