import { Form, Input, Button, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { ApiListQueryCategory } from '@/request/api';
import { toRes } from '@/utils';
import Loading from '@/utils/loading';
import moment from 'moment-timezone';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { globalState } from '@/stores';
import './index.scss'

export default function Categories () {
  const { i18n } = globalState;

  const [form] = Form.useForm()

  const [loading, setLoading] = useState<boolean>(true)
  const [tableData, setTableData] = useState([])
  const [pager, setPager] = useState({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const paramsRef = useRef({ name: '', id: '' })
  const init = async () => {
    await fetchTableData();
  }
  useEffect(() => {
    init()
  }, []);

  const fetchTableData = async ({ current, pageSize }: { current?: number, pageSize?: number } = {}) => {
    const params = {
      name: paramsRef.current.name ?? '',
      id: paramsRef.current.id ?? '',
      pageIndex: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize
    }
    setLoading(true)
    const [_, data] = await toRes(ApiListQueryCategory(params));
    setLoading(false)
    setTableData(data?.dataList ?? [])
    setPager((prev) => ({
      ...prev,
      pageSize: data?.pageSize ?? 10,
      pageIndex: data?.pageIndex ?? 1,
      total: data?.total ?? 0,
      current: current ?? prev.current
    }))
  }

  const handleSearch = () => {
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const handleReset = () => {
    form.resetFields();
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const updateParamsRef = () => {
    paramsRef.current = form.getFieldsValue();
  }

  const handleTableChange = (_pagination) => {
    fetchTableData({ current: _pagination.current, pageSize: _pagination.pageSize });
  }

  const tableColumns = [
    { dataIndex: 'id', key: 'id', title: 'ID', width: 50 },
    { dataIndex: 'name', key: 'name', title: 'name', width: 200 },
    { dataIndex: 'displayOrder', key: 'displayOrder', title: 'displayOrder', width: 80 },
    { dataIndex: 'bizTypeDesc', key: 'bizTypeDesc', title: 'bizType', width: 150, render: (val) => i18n?.t(val.replace('{', '').replace('}', '')) ?? '' },
    { dataIndex: 'createTime', key: 'createTime', title: 'createTime', width: 200, render: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss') },
    { dataIndex: 'modifiedTime', key: 'modifiedTime', title: 'modifiedTime', width: 200, render: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss') },
  ]

  return (
    <div className="categories">
      <div className="m-title">
        <div className="title">{ i18n.t('Categories') }</div>
      </div>
      <div className="filter-wrap">
        <Form form={ form } layout="inline">
          <Form.Item name={ 'name' }>
            <Input width={ 400 } placeholder={ i18n.t('enter_x', { msg: 'Name' }) }/>
          </Form.Item>
          <Form.Item name={ 'id' }>
            <Input width={ 400 } placeholder={ i18n.t('enter_x', { msg: 'ID' }) }/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={ <SearchOutlined/> } onClick={ handleSearch }>
              { i18n.t('search') }
            </Button>
          </Form.Item>
          <Form.Item>
            <Button icon={ <RedoOutlined/> } onClick={ handleReset }>
              { i18n.t('reset') }
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="table-wrap">
        <Table
          columns={ tableColumns }
          pagination={ pager }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowKey={'key'}
          loading={ loading }
          onChange={ handleTableChange }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>
    </div>
  )
}