import React, { FC, useEffect, useState } from 'react'
import DragTable, { DragHandle } from '@/Components/DragTable'
import { globalState } from '@/stores'
import { ColumnsType } from 'antd/es/table';
import { Button, Flex, Input, InputNumber, Select, Space, Spin, message } from 'antd'
import type { DragEndEvent } from '@dnd-kit/core';
import { generateRandomId, isEmpty, debounce, formatFullPrice } from '@/utils';
import './ModifierTable.scss';
import { CloseCircleOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import AddModifierGroup from '../component/AddModifierGroup';
import EditModifier from '../component/EditModifier';
import { ApiModifierList } from '@/request/Menus/CreateModifier'
import {
  arrayMove
} from '@dnd-kit/sortable';
import { SearchEnum } from './enum';
interface IProps {
  resetOption: (options: any[]) => void,
  options: any[]
}
const ModifierTable: FC<IProps> = (props) => {
  const { i18n } = globalState;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState([])
  const [modifiedList, setModifiedList] = useState([])
  const [chooseModifiers, setChooseModifiers] = useState<Array<any>>([]);
  const [searchItemList, setSearchItemList] = useState([])
  const tableColumns = [
    {
      key: 'sort',
      align: 'center',
      width: 50,
      render: () => <DragHandle />
    },
    {
      dataIndex: 'name',
      key: 'name',
      title: <>{i18n.t('createMenuGroup_pc_menu_group')} <span style={{ 'color': 'red' }}>*</span></>,
      render: (text, record) => <div>
        <Input value={text} onChange={e => {
          handleInputChange(e.target.value, record.uuid, 'name');
        }}
        onBlur={() => {
          handleInputBlur(record);
        }}
        maxLength={100}
        />
        {chooseModifiers.map(ele => ele.uuid).includes(record.uuid) && !record.name && <div style={{ 'color': '#ff4d4f' }}> {i18n?.t('please_enter_x', { msg: i18n?.t('menu_modifier_name') })} </div>}
      </div>
    },
    {
      dataIndex: 'foreignName',
      key: 'foreignName',
      title: i18n.t('foreign_name'),
      render: (text, record) => <Input value={text} maxLength={100} onChange={e => handleInputChange(e.target.value, record.uuid, 'foreignName')} />,
    },
    {
      dataIndex: 'modifierPrice',
      key: 'modifierPrice',
      title: i18n.t('price'),
      width: 170,
      render: (text, record) => <InputNumber prefix='$' style={{ 'width': '100%' }} value={text} min={0} max={999999} precision={2} onChange={e => handleInputChange(e, record.uuid, 'modifierPrice')} />,
    },
    {
      dataIndex: 'delete',
      align: 'center',
      render: (_, record) => <Flex justify='end'>
        <Button type="link" icon={<CloseCircleOutlined />} onClick={() => removeItem(record)} ></Button>
      </Flex>
    }
  ]
  const handleInputChange = (value, uuid, column) => {
    const newData = [...tableData];
    const index = newData.findIndex(item => uuid === item.uuid);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, [column]: value });
      setTableData(newData);
    }
  };
  const handleInputBlur = (record) => {
    const { uuid } = record;
    const index = chooseModifiers.findIndex(find => find.uuid === uuid);
    if (index > -1) {
      const newArr = [...chooseModifiers];
      const newData = chooseModifiers[index];
      newData.name = record.name;
      newArr.splice(index, 1, newData);
      setChooseModifiers(newArr);
    } else {
      setChooseModifiers([
        ...chooseModifiers,
        record
      ]);
    }
  }
  const removeItem = (item) => {
    const index = tableData.findIndex(ele => ele.uuid === item.uuid);
    const newData = [...tableData];
    newData.splice(index, 1)
    setTableData(newData);
  }
  const searchItem = async (value) => {
    const params = {
      archived: false,
      name: value.searchQuery,
      nameType: SearchEnum[value.searchType]
    }
    const res = await ApiModifierList({
      request: params
    })
    if (res.code === 0) {
      setSearchItemList(res.data)
    }
  }

  const inputItem = (e: any) => {
    debounceInput(e)
  }
  const debounceInput = debounce(searchItem, 500)

  const onSelectedInfo = (row) => {
    // 需要先将通过 new 按钮新增的数据滤除
    const filterData = tableData.filter(fil => fil.type !== 'defaultModifier')
    if (row.length > filterData.length) {
      // 新增的数据
      const filterList = row.filter(r => !tableData.some(t => t.uuid === r.uuid));
      const added = filterList.map(ele => {
        const { modifierPrice, name, uuid, foreignName } = ele;
        return {
          modifierPrice,
          name,
          foreignName,
          uuid,
          type: 'existingModifier'
        }
      });
      setTableData([
        ...tableData,
        ...added
      ])
    } else if (row.length < filterData.length) {
      // 被删除的数据
      const deleted = tableData.filter(t => !row.some(r => r.uuid === t.uuid) && t.type !== 'defaultModifier');
      const deletedIds = deleted.map(m => m.uuid);
      const filterArr = tableData.filter(item => !deletedIds.includes(item.uuid));
      setTableData(filterArr);
    }
  }

  const addNew = () => {
    setTableData([
      ...tableData,
      {
        uuid: generateRandomId(),
        name: '',
        foreignName: '',
        modifierPrice: 0.00,
        type: 'defaultModifier'
      }
    ])
  }
  const editItem = (item) => {
    setModifiedList([item]);
    setOpenModal(true);
  }
  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over.id) {
      const id = tableData.find(f => f.uuid === active.id).uuid;
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.uuid === active?.id);
        const overIndex = prevState.findIndex((record) => record.uuid === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };
  const handleOk = (arr: any[]) => {
    const newData = [...tableData];
    const item = arr[0];
    const index = tableData.findIndex(fin => fin.uuid === item.uuid);
    newData[index] = item;
    setTableData(newData);
  }
  useEffect(() => {
    props.resetOption([...tableData]);
  }, [
    tableData
  ])
  useEffect(() => {
    setTableData([...props.options]);
  }, [props.options])
  return <div className='create-modifier-group-table'>
    <div className='create-modifier-group-table-title'>
      {i18n?.t('modifier')}
    </div>
    {
      !!tableData.length ? <>
        <DragTable
          onDragEnd={onDragEnd}
          rowKey={'uuid'}
          columns={tableColumns as ColumnsType}
          dataSource={tableData} />
        <Flex justify='end'>
          <Button type='link' onClick={addNew}>{i18n.t('+_add')}</Button>
        </Flex>
      </> : <>
        <Button icon={<PlusOutlined />} style={{ marginBottom: 15 }} onClick={addNew}>
          New Modifier
        </Button>
      </>
    }
    <AddModifierGroup
      type="modifier"
      dataList={searchItemList}
      rowKey='uuid'
      defaultSelectionRow={tableData}
      onGetSearchInfo={inputItem}
      onSelectedInfo={onSelectedInfo} />
    <EditModifier openModal={openModal} setOpenModal={setOpenModal} modifiedList={modifiedList} handleOk={handleOk} />
  </div >
}
export default ModifierTable;