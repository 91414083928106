import React, { useEffect, useState } from 'react'
import TextLineNew from '../component/TextLineNew'
import './index.scss'
import printOutDecorationStore from '../../store'
import { toJS } from 'mobx'
const TipCustomSection = () => {

  const [isHideTipSuggestSection, setIsHideTipSuggestSection] = useState(false)

  useEffect(() => {
    const previewData = toJS(printOutDecorationStore.getPreviewData)
    previewData.forEach(item => {
      if (item.id === 'tip-suggestion-section') {
        setIsHideTipSuggestSection(item.hide)
      }
    })
  }, [printOutDecorationStore.getPreviewData])

  return <div className='tip-custom-area'>

    {
      isHideTipSuggestSection && (
        <TextLineNew
          text='Tips'
          style={{
            fontSize: 'md',
          }}
        />
      )
    }

    <div className='tip-custom-area-item'>
      <div className='tip-custom-area-item-bracket'>[&nbsp;&nbsp;]</div>
      <div className='tip-custom-area-item-custom-tips'>
        <div className='tip-custom-area-item-custom-tips-block'>
          <div className='tip-custom-area-item-custom-tips-block-top-line'></div>
          <TextLineNew text='Custom Tips'/>
        </div>
        <div className='tip-custom-area-item-custom-tips-block'>
          <div className='tip-custom-area-item-custom-tips-block-top-line'></div>
          <TextLineNew text='Total'/>
        </div>
      </div>
    </div>
  </div>
}
export default TipCustomSection;