import { IBaseDefaultMapData, ITag } from '../interface';

export const defaultZReportMapData: Array<IBaseDefaultMapData<ITag>> = [
  {
    'id': 'payment-details-section',
    'title': 'payment_details_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'tips-section',
    'title': 'tips_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'employee-totals-section',
    'title': 'Employee Totals',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'total-voids-section',
    'title': 'void_reason',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'total-discounts-section',
    'title': 'total_discounts_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'refunds-section',
    'title': 'refunds_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'sales-categories-section',
    'title': 'sales_categories_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'dining-options-section',
    'title': 'dining_options_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'sales-channel-section',
    'title': 'pc_printer_saleschannel',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'service-charge-section',
    'title': 'service_charge_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'cash-drawer-section',
    'title': 'cash_drawer_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
]