import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { message, Modal, Table } from 'antd';
import { toRes } from '@/utils';
import { ApiGetRoleRelateEmployeeList, ApiRoleUnarchived } from '@/request/api';
import { TableEmptyContent } from '@/Components/EmptyContent';
import Loading from '@/utils/loading';
import './index.scss'

export default function ModalRoleActivateList (props) {
  const { i18n } = globalState;
  const { item, fetchData } = props;

  const [visible, setVisible] = useState(true);

  const [tableData, setTableData] = useState([])
  const [pager, setPager] = useState({
    current: 1, pageSize: 100, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => i18n.t('table_total_items', { num: total }),
  })
  const [selectedRows, setSelectedRows] = useState([])

  const [loading, setLoading] = useState(false)


  useEffect( () => {
    fetchTableData();
  }, []);

  const tableColumns = [
    { dataIndex: 'firstName', key: 'firstName', title: i18n.t('employeeAdd_pc_first_name'), width: 100, render: (_, row) => row.employee.firstName },
    { dataIndex: 'lastName', key: 'lastName', title: i18n.t('myAccount_pc_last_name'), width: 100, render: (_, row) => row.employee.lastName },
    { dataIndex: 'email', key: 'email', title: 'Email', width: 100, render: (_, row) => row.employee.email },
  ]

  const fetchTableData = async ({ current, pageSize }: { current?: number, pageSize?: number } = {}) => {
    const params = {
      roleId: props.item.roleId,
      pageIndex: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize
    }
    setLoading(true)
    const [_, data] = await toRes(ApiGetRoleRelateEmployeeList(params));
    setLoading(false)
    setTableData(data?.dataList ?? [])
    setPager((prev) => ({
      ...prev,
      pageSize: data?.pageSize ?? 100,
      pageIndex: data?.pageIndex ?? 1,
      total: data?.total ?? 0,
      current: current ?? prev.current
    }))
  }

  const rowSelection = {
    type: 'checkbox',
    onChange: (_, _selectedRows) => {
      setSelectedRows(_selectedRows)
    },
  }

  const handleTableChange = (_pagination) => {
    fetchTableData({ current: _pagination.current, pageSize: _pagination.pageSize });
  }

  const handleOk = async (e) => {
    if (e.target.closest('.ant-modal-close-x')) {
      setVisible(false);
      props.close();
      return;
    }
    const employeeIdList = selectedRows.map(x => x.employee.employeeId)
    Loading.show();
    const [err] = await toRes(ApiRoleUnarchived({ roleId: item.roleId, employeeIdList }))
    Loading.hide();
    if (err) return;
    fetchData();
    message.success(i18n.t('message_success'));
    setVisible(false);
    props.close();
  }


  return (
    visible && <Modal
      width={ 800 }
      title={ i18n.t('employee_pc_Activate_job') }
      visible={ visible }
      onCancel={ handleOk }
      cancelText={ i18n.t('employees_pc_do_not_add') }
      onOk={ handleOk }
    >
      <div className='modalRoleActivateList'>
        <div className='tips'>{ i18n.t('employee_pc_activate_role_tips', { name: item.roleName }) }</div>
        <div className='tableWrap'>
          <Table
          // @ts-expect-error todo
            rowSelection={ rowSelection }
            rowKey={record => record.employee.employeeId}
            loading={ loading }
            columns={ tableColumns } dataSource={ tableData }
            pagination={ pager }
            scroll={ { x: 'max-content' } }
            onChange={ handleTableChange }
            locale={ { emptyText: TableEmptyContent } }
          />
        </div>
      </div>
    </Modal>
  )
}