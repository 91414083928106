import './index.scss'
import { Button, Modal, Input, Form, message, Radio, Popover, Select, Space } from 'antd';
import { globalState } from '@/stores';
import { PlusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react';
import { batchAddDiningOption,
  queryAllDiningOption,
  batchArchivedDiningOptionAction,
  sortDiningOptionAction,
  updateDefaultOption,
  orderModeList } from '@/request/DiningOptions';
import { ArchivedPointIcon, ActivePointIcon, ArchivedIcon, ActiveIcon } from '../component/IconComponent';

import type { DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove
} from '@dnd-kit/sortable';
import { isStrictlyEmptyString } from '@/utils'
import DragTable, { DragHandle } from '@/Components/DragTable';
import { DiningOptionsEnum, TabType } from '../enum';
import { set } from 'mobx';
interface IProps{
  activeKey: TabType
}
interface IOrderModeOption {
  label: string
  value: string
}
export default function DiningOptionsTable (props: IProps) {
  const [diningOptionsForm] = Form.useForm();
  const i18n = globalState.i18n;
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [modalOpen, setModalOpen] = useState<boolean>();
  const [tableData, setTableData] = useState([])
  const [editTableData, setEditTableData] = useState([])
  const [orderModOption, setOrderModOption] = useState<IOrderModeOption[]>();
  const DEFAULT_FILTER_TYPE = {
    'STATUS': ['1'],
  }
  const STATUS_FILTER = [{ text: i18n.t('active'), value: '1' }, { text: i18n.t('archived'), value: '2' }]
  const [statusFilter] = useState(STATUS_FILTER);
  const [filteredValue, setFilteredValue] = useState(DEFAULT_FILTER_TYPE.STATUS);

  const [params, setParams] = useState({ status: ['1'] });

  useEffect(() => {
    fetchTableData();
  }, [params])

  useEffect(() => {
    getOrderModeList()
  }, [])

  useEffect(() => {
    if (props.activeKey === TabType.DININGOPTION) {
      fetchTableData();
    }
  }, [props])

  const getOrderModeList = async () => {
    const res = await orderModeList();
    if (res?.code === 0) {
      const list = res.data.map((item) => {
        return {
          displayName: i18n.t(item.displayName),
          value: item.value
        }
      })
      console.log(list)
      setOrderModOption(list);
    }
  }
  const handleOk = async () => {
    const res = await diningOptionsForm.validateFields();
    if (res) {
      const response = await batchAddDiningOption({
        options: {
          name: res['name'],
          orderMode: res['orderMode']
        }
      });
      if (response?.code === 0) {
        message.success(response.message);
        diningOptionsForm.resetFields();
        setModalOpen(false);
        fetchTableData();
      } else {
        message.error(response.message);
      }
    }
  }
  const saveTableData = async () => {
    const validatorIndexes = editTableData.reduce((acc, item, index) => {
      if (item.name.length > 20 || item.name.length < 1) {
        acc.push(index + 1);
      }
      return acc;
    }, []);
    console.log(validatorIndexes)
    if (validatorIndexes.length > 0) {
      message.error(`${i18n?.t('frontOfHouse_pc_the_input')},${i18n?.t('frontOfHouse_pc_maximum', { length: 20 })}. Please check the data for items ${validatorIndexes.join(',')}`);
      return;
    }
    setLoading(true);
    const response = await batchAddDiningOption({
      options: editTableData
    });
    setLoading(false);
    setIsEdit(false);
    if (response?.code === 0) {
      fetchTableData();
    } else {
      message.error(response.message);
    }
  }
  const handleCancel = () => {
    setModalOpen(false);
    diningOptionsForm.resetFields();
  }
  const openDiningOptionModal = () => {
    setModalOpen(true);
  }
  const handleFormInputBlur = (form, key) => {
    form.validateFields([key]);
  }
  const setDefaultOption = async (row) => {
    const response = await updateDefaultOption({
      id: row.id,
      uuid: row.uuid
    });
    if (response.code === 0) {
      message.success(response.message);
      fetchTableData();
    }
  }

  const archiveDiningOption = async (row) => {
    const { uuid, archived } = row;
    const res = await batchArchivedDiningOptionAction({
      uuids: [uuid],
      archived: !archived
    });
    if (res?.code === 0) {
      fetchTableData();
    }
  }

  const changeRowDiningOption = (val: string, index: number) => {
    editTableData[index].name = val;
    setEditTableData([...editTableData]);
  }
  const changeOrderMode = (val:IOrderModeOption, index: number) => {
    console.log(val)
    editTableData[index].orderMode = val;
    setEditTableData([...editTableData]);
  }
  const tableColumns: any = [
    { key: 'sort', align: 'center', width: 50, render: () => <DragHandle /> },
    {
      dataIndex: 'name', key: 'name', title: i18n.t('frontOfHouse_pc_dining_option'), width: 100, align: 'center',
      render: (data, row, index) => {
        return (
          <div>
            {
              isEdit ? (
                <Input value={data} onChange={(val) => {
                  changeRowDiningOption(val.currentTarget.value, index);
                }} />
              ) : (
                <span>{row.name}</span>
              )
            }
          </div>
        )
      }
    },
    {
      dataIndex: 'orderMode', key: 'orderMod', title: i18n.t('Dashboard_DiningOption_OrderMode'), width: 140, align: 'center',
      render: (data, row, index) => {
        return (
          <div>
            {
              isEdit && row.defaultCreated !== 1 ? (
                <Select value={data}
                  fieldNames={{ label: 'displayName', value: 'value' }}
                  options={orderModOption}
                  labelInValue
                  onChange={(val) => {
                    changeOrderMode(val, index);
                  }} />
              ) : (
                <span>{i18n.t(data.displayName)}</span>
              )
            }
          </div>
        )
      }
    },
    {
      dataIndex: 'defaultQuickService', key: 'defaultQuickService', title: i18n.t('Dashboard_DiningOption_QuickOrderDefault'), width: 140, align: 'center',
      render: (_, row) => {
        return (
          <div>
            <Radio.Group value={+row.defaultQuickService} onChange={() => { setDefaultOption(row) }} disabled={row?.archived}>
              <Radio value={1}></Radio>
            </Radio.Group>
          </div>
        )
      }
    },
    {
      dataIndex: 'archived', key: 'archived', title: i18n.t('status'), width: 50, align: 'center',
      defaultFilteredValue: DEFAULT_FILTER_TYPE.STATUS,
      filteredValue: filteredValue,
      filters: statusFilter,
      filterResetToDefaultFilteredValue: true,
      render: (_, row) => {
        return (
          <div>
            {row?.archived ? <span><ArchivedPointIcon /> Archived</span> : <span><ActivePointIcon /> Active</span>}
          </div>
        )
      }
    },
    {
      key: 'actions', title: i18n.t('table_actions'), width: 50, align: 'center',
      render: (_, row) => {
        return (
          <div >
            {row.defaultCreated === 1 || row.defaultQuickService === 1 ?
              <Button type="link" icon={<MoreOutlined />} disabled={true}></Button> :
              <Popover content={
                <Button type="link" onClick={() => {
                  archiveDiningOption(row);
                }}>
                  {
                    <p style={{ 'display': 'flex', 'alignItems': 'center' }}>
                      { row.archived ? <ArchivedIcon /> : <ActiveIcon />}
                      <span style={{ 'marginLeft': '5px' }}>{i18n?.t?.(row.archived ? 'unarchived' : 'archive')}</span>
                    </p>
                  }
                </Button>
              }>
                <Button type="link" icon={<MoreOutlined />}></Button>
              </Popover>
            }
          </div>
        )
      }
    },
  ];

  const fetchTableData = async () => {
    setLoading(true)
    const { code, data } = await queryAllDiningOption(params);
    if (code === 0) {
      setTableData(JSON.parse(JSON.stringify(data)));
      setEditTableData(JSON.parse(JSON.stringify(data)));
    }
    setLoading(false)
  }

  const handleTableChange = (_, filters) => {
    setFilteredValue(filters.Status);
    setParams(
      {
        status: filters.archived
      }
    )
  }
  const cancel = () => {
    setIsEdit(false);
    console.log(tableData[0].name)
    setTimeout(() => {
      setEditTableData(JSON.parse(JSON.stringify(tableData)));
    }, 1);
  }
  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over.id) {
      const uuid = editTableData.find(f => f.uuid === active.id).uuid;
      const targetSort = editTableData.find(f => f.uuid === over.id).displayOrder;
      setEditTableData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.uuid === active.id);
        const overIndex = prevState.findIndex((record) => record.uuid === over.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      const res = await sortDiningOptionAction({
        uuid,
        targetSort
      })
      if (res.code === 0) {
        message.success(res.message);
        fetchTableData();
      } else {
        message.error(res.message);
      }
    }
  };
  return (
    <div className="dining-options-content">
      <div className="dining-options-content-tabpane">
        <Space size="middle">
          {isEdit && <Button onClick={cancel}>{i18n.t('cancel')}</Button>}
          {isEdit && <Button type="primary" onClick={saveTableData}>{!isEdit ? i18n.t('edit') : i18n.t('save')}</Button>}
          {!isEdit && <Button type="primary" onClick={() => setIsEdit(true)}>{i18n.t('edit')}</Button>}
        </Space>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={openDiningOptionModal}>{i18n.t('new_dining_option')}</Button>
      </div>
      <div className="dining-options-content-tableWrap">
        <DragTable
          loading={loading}
          onDragEnd={onDragEnd}
          rowKey={'uuid'}
          columns={tableColumns}
          dataSource={editTableData}
          onChange={handleTableChange}>
        </DragTable>
      </div>
      <Modal title={i18n.t('frontOfHouse_pc_dining_option')} open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form={diningOptionsForm}
          validateTrigger="onSubmit"
        >
          <Form.Item
            name="name"
            label={i18n.t('frontOfHouse_pc_dining_option')}
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (isStrictlyEmptyString(value)) {
                    return Promise.reject(i18n?.t('frontOfHouse_pc_the_input'));
                  }
                  if (value.length > 20) {
                    return Promise.reject(i18n?.t('frontOfHouse_pc_maximum', { length: 20 }))
                  }
                  return Promise.resolve()
                }
              }),
            ]}
          >
            {<Input required onBlur={() => { handleFormInputBlur(diningOptionsForm, 'name') }} />}
          </Form.Item>
          <Form.Item
            name="orderMode"
            label={i18n.t('Dashboard_DiningOption_OrderMode') || 'OrderMode'}
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (isStrictlyEmptyString(value)) {
                    return Promise.reject(i18n?.t('frontOfHouse_pc_the_input'));
                  }
                  return Promise.resolve()
                }
              }),
            ]}
          >
            <Select
              options={orderModOption}
              fieldNames={{ label: 'displayName', value: 'value' }}
              onBlur={() => { handleFormInputBlur(diningOptionsForm, 'orderMode') }}
              labelInValue
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )

}