import { Form, Input, Button, Table, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { ApiPermissionCategoryReferData, ApiQueryEntrancePage } from '@/request/api';
import { toRes } from '@/utils';
import Loading from '@/utils/loading';
import { globalState } from '@/stores';
import moment from 'moment-timezone';
import { SelectEmptyContent, TableEmptyContent } from '@/Components/EmptyContent';
import './index.scss'

export default function Entrances () {
  const i18n = globalState.i18n;

  const [form] = Form.useForm()
  const [isInit, setIsInit] = useState<boolean>(false)
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState<boolean>(true)
  const [categoryList, setCategoryList] = useState([])
  const [pager, setPager] = useState({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => i18n.t('table_total_items', { num: total }),
  })
  const paramsRef = useRef({ name: '', category: null })


  const init = async ()=>{
    await fetchTableData();
    await fetchCategoryList();
    setIsInit(true)
    setLoading(false)
  }
  useEffect(() => {
    init()
  }, []);

  const fetchTableData = async ({ current, pageSize }: { current?: number, pageSize?: number } = {}) => {
    const params = {
      name: paramsRef.current.name ?? '',
      categoryId: paramsRef.current.category ?? null,
      pageIndex: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize
    }
    setLoading(true)
    const [_, data] = await toRes(ApiQueryEntrancePage(params));
    isInit && setLoading(false)
    setTableData(data?.dataList ?? [])
    setPager((prev) => ({
      ...prev,
      pageSize: data?.pageSize ?? 10,
      pageIndex: data?.pageIndex ?? 1,
      total: data?.total ?? 0,
      current: current ?? prev.current
    }))
  }

  const fetchCategoryList = async () => {
    const [_, data] = await toRes(ApiPermissionCategoryReferData());
    setCategoryList(data?.map(x => ({ label: x.name, value: x.id })) ?? [])
  }

  const handleSearch = () => {
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const handleReset = () => {
    form.resetFields();
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const updateParamsRef = () => {
    paramsRef.current = form.getFieldsValue();
  }

  const handleTableChange = (_pagination) => {
    fetchTableData({ current: _pagination.current, pageSize: _pagination.pageSize });
  }

  const tableColumns = [
    { dataIndex: 'id', key: 'id', title: 'ID', width: 50 },
    { dataIndex: 'name', key: 'name', title: 'name', width: 150, render: (val) => i18n.t(val.replace('{', '').replace('}', '')) },
    { dataIndex: 'categoryName', key: 'categoryName', title: 'category', width: 150 },
    { dataIndex: 'url', key: 'url', title: 'url', width: 150 },
    // { dataIndex: 'bizObjUri', key: 'bizObjUri', title: 'bizObjURI', width: 150 },
    // { dataIndex: 'uri', key: 'uri', title: 'uri', width: 300 },
    { dataIndex: 'permissionId', key: 'permissionId', title: 'permissionCode', width: 120 },
    { dataIndex: 'displayOrder', key: 'displayOrder', title: 'order', width: 80 },
    { dataIndex: 'archived', key: 'archived', title: 'isArchived', width: 120, render: (item) => item ? 'True' : 'False' },
    { dataIndex: 'createTime', key: 'createTime', title: 'createTime', width: 200, render: (item) => moment(item).format('YYYY-MM-DD HH:mm:ss') },
    { dataIndex: 'modifiedTime', key: 'modifiedTime', title: 'modifiedTime', width: 200, render: (item) => moment(item).format('YYYY-MM-DD HH:mm:ss') },
    { dataIndex: 'icon', key: 'icon', title: 'icon' },
  ]

  return (
    <div className='entrances'>
      <div className='title'>{ i18n.t('menu_entrances') }</div>
      <div className='filterWrap'>
        <Form form={ form } layout="inline">
          <Form.Item name={ 'name' }>
            <Input width={ 400 } placeholder={ i18n.t('enter_x', { msg: 'Name' }) }/>
          </Form.Item>
          <Form.Item name={ 'category' }>
            <Select
              style={ { minWidth: '150px' } }
              showSearch
              notFoundContent={ SelectEmptyContent }
              placeholder={ i18n.t('select_x', { msg: i18n.t('permission_category') }) }
              onChange={ (x) => form.setFieldValue('category', x) }
              filterOption={ (input, option) => ((option?.label ?? '') as string).includes(input) }
              options={ categoryList }
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={ <SearchOutlined/> } onClick={ handleSearch }>
              { i18n.t('search') }
            </Button>
          </Form.Item>
          <Form.Item>
            <Button icon={ <RedoOutlined/> } onClick={ handleReset }>
              { i18n.t('reset') }
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className='tableWrap'>
        <Table
          columns={ tableColumns }
          loading={ loading }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowKey={'key'}
          pagination={ pager }
          scroll={ { x: 'max-content' } }
          onChange={ handleTableChange }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>
    </div>
  )
}