import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
interface IProps{
  text: string
}
const CreditCardPaymentDetailsSection = (props: IProps) => {
  return <div className='credit-card-payment-details'>
    <div className='credit-card-payment-details-item'>
      <TextLineNew text='CREDIT CARD PAYMENT DETAILS' style={{
        fontWeight: 'bold',
      }}/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='credit-card-payment-details-check'>
      <TextLineNew text='Check #001' style={{
        fontWeight: 'bold',
      }}/>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Card type1 9999'/>
        <TextLineNew text='$0.00'/>
      </div>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Card type2 8888'/>
        <TextLineNew text='$0.00'/>
      </div>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Check Total'/>
        <TextLineNew text='$0.00'/>
      </div>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Tip'/>
        <TextLineNew text='$0.00'/>
      </div>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Paid Total'/>
        <TextLineNew text='$0.00'/>
      </div>
    </div>
    <div className='credit-card-payment-details-check'>
      <TextLineNew text='Check #002' style={{
        fontWeight: 'bold',
      }}/>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Card type1 7777'/>
        <TextLineNew text='$0.00'/>
      </div>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Card type2 6666'/>
        <TextLineNew text='$0.00'/>
      </div>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Check Total'/>
        <TextLineNew text='$0.00'/>
      </div>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Tip'/>
        <TextLineNew text='$0.00'/>
      </div>
      <div className='credit-card-payment-details-check-info'>
        <TextLineNew text='Paid Total'/>
        <TextLineNew text='$0.00'/>
      </div>
    </div>
  </div>
}
export default CreditCardPaymentDetailsSection;