import React, { useState, useEffect, useRef } from 'react';
import { ApiQueryPrintConfigDetail } from '@/request/PrintOutDecorations'
import './DecorationPrintOut.scss'
import { observer } from 'mobx-react';
import Header from './component/Header';
import Tab from './component/Tab';
import ConfigUIContainer from './configUI';
import PreviewUIContainer from './previewUI';
import { getQuerys } from '@/utils';
import printOutDecorationStore from './store';
import { ConfigTypeValue, IPreviewSectionAttributes } from './interface';
import { toJS } from 'mobx';
import { ApiQueryByNodeId } from '@/request/api';
import { globalState } from '@/stores';
// import  { receiptPreviewMockData } from './mockData/receiptPreviewData'
import { defaultKitchenMapData } from './defaultJsonData/defaultKitchen'
// 暂时使用mock数据
import { kitchenPreviewMockData } from './mockData/kitchenPreviewData';
import { zReportPreviewMockData } from './mockData/zReportPreviewData';
import { shiftPreviewMockData } from './mockData/shiftPreviewData';
import { refundPreviewMockData } from './mockData/refundPreviewData';
import { checkPreviewMockData } from './mockData/checkPreviewData';
import { receiptPreviewMockData } from './mockData/receiptPreviewData';
import { handleDataAndFieldChanges } from './utils/fnHandleNewAddFields';



export default observer(function DecorationPrintOut () {
  const { i18n } = globalState;

  const [tabConfigType, setTabConfigType] = useState<ConfigTypeValue>('Check');
  const [previewHeight, setPreviewHeight] = useState<string>('70vh');
  const configContainerRef = useRef(null);

  const formatConfigDetails = (configDetails: IPreviewSectionAttributes[]) => {
    // item info的间距更新，s17的间距大小从1，14，28改为5，15，30
    return configDetails.map((m: IPreviewSectionAttributes) => {
      if (m.children['item-info-section-dishLineSpacing']) {
        const value = m.children['item-info-section-dishLineSpacing'].value;
        const options = [['1', '5'], ['14', '15'], ['28', '30']];
        const finalSpacing = options.find(f => f.includes(value))?.[1] || '15';
        m.children['item-info-section-dishLineSpacing'].value = finalSpacing
      }
      return m;
    })
  }
  const fetchData = async () => {
    // 获取路由参数type
    const typeParamsInUrl = toJS(printOutDecorationStore.getUrlParamsType)
    const { code, data } = await ApiQueryPrintConfigDetail({
      configType: typeParamsInUrl || 'Check'
    });
    if (code === 0) {
      const { configType, version } = data;
      setTabConfigType(configType)
      printOutDecorationStore.saveConfigVersion(version)
      printOutDecorationStore.savePreviewData(formatConfigDetails(data.configDetails), 'fetchApi')

      printOutDecorationStore.saveIsEditPrintOutConfig(false)
      printOutDecorationStore.saveDefaultConfigTemplateData(data.defaultConfigTemplate)

      // *** mock ***
      // if (typeParamsInUrl === 'Check') {
      //   printOutDecorationStore.saveDefaultConfigTemplateData(checkPreviewMockData)
      // } else if (typeParamsInUrl === 'Kitchen') { // mock代码，接口正常后会删除
      //   console.log('kitchen')
      // printOutDecorationStore.saveDefaultConfigTemplateData(kitchenPreviewMockData)
      // }
      // else if (typeParamsInUrl === 'ZReport') { // mock代码，接口正常后会删除
      // printOutDecorationStore.saveDefaultConfigTemplateData(zReportPreviewMockData)
      // } else if (typeParamsInUrl === 'ShiftReport') { // mock代码，接口正常后会删除
      //   printOutDecorationStore.saveDefaultConfigTemplateData(shiftPreviewMockData)
      // } else if (typeParamsInUrl === 'Refund') { // mock代码，接口正常后会删除
      //   printOutDecorationStore.saveDefaultConfigTemplateData(refundPreviewMockData)
      // } else if (typeParamsInUrl === 'Receipt') { // mock代码，接口正常后会删除
      //   printOutDecorationStore.saveDefaultConfigTemplateData(receiptPreviewMockData)
      // }

      // 处理新增/删除的数据和字段，处理sortUI的顺序 -> 处理后的数据
      handleDataAndFieldChanges(configType, 'fetchApi')
    }


  }

  const fetchRestaurantInfo = async () => {
    const res = await ApiQueryByNodeId({})
    if (res && res.code === 0) {
      const { name, contactPhone, streetAddress, city, state, zipcode } = res.data
      printOutDecorationStore.saveRestaurantInfo({ name, contactPhone, streetAddress, city, state, zipcode })

      fetchData()
    }
  }


  useEffect(() => {
    const { type } = getQuerys(location.href);
    printOutDecorationStore.saveUrlParamsType((type || 'Check') as ConfigTypeValue)

    fetchRestaurantInfo();
  }, []);

  useEffect(() => {
    const previewData = toJS(printOutDecorationStore.getPreviewData);
    switch (tabConfigType) {
    case 'Kitchen':
      // TODO: 这种逻辑是否完全需要？0911
      const hide = previewData.find(fin => fin.id === 'other-prep-station-section')?.['hide'];

      setPreviewHeight(!hide ? '750px' : '60vh');
      break;
    default:
      setPreviewHeight('70vh');
      break;
    }
  }, [printOutDecorationStore.getPreviewData, tabConfigType])

  return (
    <div className='decoration-print-out'>
      <Header />

      <Tab
        tabConfigType={tabConfigType}
        reloadPrintConfig={() => { fetchData() }}
      />

      <div className='decoration-container'>
        <div className='config-container'>
          <ConfigUIContainer ref={configContainerRef} />
        </div>

        <div>
          <div
            className='preview-container'
            style={{
              height: previewHeight,
              overflowY: 'auto'
            }}
          >
            <PreviewUIContainer />
          </div>

          {
            ['Receipt', 'Refund'].includes(tabConfigType) && (
              <div className='note-info'>
                Note: {i18n.t('Dashboard_PrintConfig_PreviewReminder')}
              </div>
            )
          }

        </div>


      </div>

    </div>
  )
})