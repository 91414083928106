import dayjs, { Dayjs } from 'dayjs';

export interface IPickupConfig {
  PICK_UP_SWITCH: boolean,
  PICK_UP_SCHEDULING_ORDERS: ISchedulingOrders,
  PICK_UP_QUOTE_TIME: IQuoteTime,
  PICK_UP_APPROVAL: IApproval,
  PICK_UP_HOURS: Array<IBusinessHour>
  PICK_UP_CUSTOMER_DEMAND: IUtensilConfig
  PICK_UP_FIRE: IKitchenCapacity
  PICK_UP_ONLINE_ORDER_URL: string
  PICK_UP_POS_DEVICE_SNS: Array<string> // 链接的Pos Sn List
}

export interface IKitchenCapacity {
  mode: KITCHEN_CAPACITY_ENUM
}

export interface IUtensilConfig {
  utensilCondimentMode:  UTENSIL_ENUM
}

export interface ISchedulingOrders {
  mode: SCHEDULING_ORDERS_ENUM
}

export interface IQuoteTime {
  mode: QUOTE_TIME_ENUM,
  customMinutes: number
}

export interface IApproval {
  mode: APPROVAL_ENUM
}

export interface IBusinessHour {
  x: any; // deprecated
  day: WEEK_DAY,
  openSwitch: boolean,
  intervalTimes: Array<{ startTime: number | string, endTime: number | string }>
}

export enum SCHEDULING_ORDERS_ENUM {
  ASAP = 1,
  PREORDER = 2
}

export const SCHEDULING_ORDERS_ENUM_I18N = {
  [SCHEDULING_ORDERS_ENUM.ASAP]: 'pc_online_orders_scheduling_orders_asap',
  [SCHEDULING_ORDERS_ENUM.PREORDER]: 'Allow online pre-order'
}

export enum QUOTE_TIME_ENUM {
  ASAP = 1,
  CUSTOM = 2
}

export const QUOTE_TIME_ENUM_I18N = {
  [QUOTE_TIME_ENUM.ASAP]: 'pc_online_orders_asap',
  [QUOTE_TIME_ENUM.CUSTOM]: 'pc_online_orders_quote_times_custom'
}


export enum APPROVAL_ENUM {
  MANUALLY = 1,
  AUTO = 2
}

// export const APPROVAL_ENUM_I18N = {
//   [APPROVAL_ENUM.MANUALLY]: 'PC_OO_ManualFire',
//   [APPROVAL_ENUM.AUTO]: 'PC_OO_AutoFire'
// }

export enum KITCHEN_CAPACITY_ENUM {
  AUTO = 1,
  MANUALLY = 2
}

export const KITCHEN_CAPACITY_ENUM_I18N = {
  [KITCHEN_CAPACITY_ENUM.MANUALLY]: 'PC_OO_ManualFire',
  [KITCHEN_CAPACITY_ENUM.AUTO]: 'PC_OO_AutoFire'
}



export enum UTENSIL_ENUM {
  UTENSIL_YES = 1,
  UTENSIL_NO = 2,
}
export const UTENSIL_ENUM_I18N = {
  [UTENSIL_ENUM.UTENSIL_YES]: 'PC_OO_Utensil_Yes',
  [UTENSIL_ENUM.UTENSIL_NO]: 'PC_OO_Utensil_No'
}



export enum WEEK_DAY {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7
}

export const WEEK_DAY_I18N = {
  [WEEK_DAY.MONDAY]: 'pc_online_orders_monday',
  [WEEK_DAY.TUESDAY]: 'pc_online_orders_tuesday',
  [WEEK_DAY.WEDNESDAY]: 'pc_online_orders_wednesday',
  [WEEK_DAY.THURSDAY]: 'pc_online_orders_thursday',
  [WEEK_DAY.FRIDAY]: 'pc_online_orders_friday',
  [WEEK_DAY.SATURDAY]: 'pc_online_orders_saturday',
  [WEEK_DAY.SUNDAY]: 'pc_online_orders_sunday'
}