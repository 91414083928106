import React, { useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import FloorPlan from '../FloorPlan';
import ServiceArea from '../ServiceArea';
import { useListener } from 'react-bus';
import { SEARCH_FLOORPLAN } from '@/utils/BUS_TYPE';

const { TabPane } = Tabs;
export default function Tables () {
  const [activeKey, setActiveKey] = useState('1');
  const [selectedAreaId, setSelectedAreaId] = useState('');
  const [enterType, setEnterType] = useState<0 | 1>(0); // 0为通过tab点击进入floorplan页面，1为edit table进入floor plan页面
  const [showFloorPlan, setShowFloorPlan] = useState<boolean>(false);
  const changeTab = (value) => {
    setEnterType(0);
    setActiveKey(value);
  }
  useListener(SEARCH_FLOORPLAN, (value) => {
    setEnterType(1);
    setSelectedAreaId(value);
    setActiveKey('2');
  })
  return (
    <Tabs activeKey={activeKey} onChange={changeTab}>
      <TabPane tab="Service Area" key={ '1' }>
        <ServiceArea updateTableData={(val) => setShowFloorPlan(val)} activeKey={activeKey}/>
      </TabPane>
      {showFloorPlan && <TabPane tab="Floor Plan" key={ '2' }>
        <FloorPlan goBack={() => { setActiveKey('1'); }} selectedAreaId={selectedAreaId} activeKey={activeKey} enterType={enterType}/>
      </TabPane>}
    </Tabs>
  )
}